import { USER_STORAGE_KEY, VISITOR_STORAGE_KEYS } from "@/enums";
import * as Sentry from "@sentry/nextjs";

import { JR_USER_ID_EDGE_CASE } from "@/constants";

import { localStorageUtil } from "./storage";

export const captureExceptionToSentry: typeof Sentry.captureException = (error, context) => {
  const JRuserId =
    localStorageUtil.get(USER_STORAGE_KEY.USER_ID) || JR_USER_ID_EDGE_CASE["NOT_LOGINED"];
  const visitorId = localStorageUtil.get(VISITOR_STORAGE_KEYS.VISITOR_ID);

  const _payload = {
    jr_userid: JRuserId,
    jr_visitid: visitorId,
  };

  Sentry.captureException(error, {
    ...context,
    user: {
      ..._payload,
      ...(context as any)?.user,
    },
    tags: {
      ..._payload,
      ...(context as any)?.tags,
    },
    extra: {
      ...(context as any)?.extra,
      release_version: process.env.RELEASE_VERSION,
    },
  });

  return "";
};

export const captureEventToSentry = (event: Sentry.Event, hint?: Sentry.EventHint | undefined) => {
  const JRuserId =
    localStorageUtil.get(USER_STORAGE_KEY.USER_ID) || JR_USER_ID_EDGE_CASE["NOT_LOGINED"];
  const visitorId = localStorageUtil.get(VISITOR_STORAGE_KEYS.VISITOR_ID);

  const payload = {
    jr_userid: JRuserId,
    jr_visitid: visitorId,
  };

  Sentry.captureEvent(
    {
      ...event,
      user: {
        ...payload,
        ...event.user,
      },
      tags: {
        ...payload,
        ...event.tags,
      },
      extra: {
        ...event.extra,
        release_version: process.env.RELEASE_VERSION,
      },
    },
    hint
  );
};

export const hitSample = (sampleRate: number) => {
  return Math.random() < sampleRate;
};
