import { ISSUE_SEVERITY, RESUME_SCORE_LEVEL, RESUME_UPDATE_SECTION } from "@/enums/jobs/resume";
import {
  TEducation,
  TPersonalInfo,
  TResumeDiagnoseItem,
  TSkills,
  TSummary,
  TWorkExperience,
} from "@/types/jobs/resume";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { notification } from "antd";

import { components } from "@/services/schema";

import { DEFAULT_KEY, NEW_SKILL_GROUP_PREFIX, RENAMED_KEY } from "@/constants/jobs/resume";

import { isEmpty } from "../lang";
import { captureExceptionToSentry } from "../sentry";

export const getFieldPlaceHolderDisplay = (field: string, map: Record<string, string>) => {
  return `${map?.[field] || field}...`;
};

interface ChatParams {
  userInput: string;
  itemId: string;
  diagnoseResultId: string;
  jrChatTemplate: string;
  resumeId: string;
}

export const requestResumeChat = ({
  onData,
  params,
  onClose,
  onError,
}: {
  params: ChatParams;
  onData: (data: any) => void;
  onClose?: () => void;
  onError?: (err: Error) => void;
}) => {
  return new Promise(resolve => {
    // NOTE: mock url
    // fetchEventSource(`http://localhost:8080/mock/chat/stream`, {
    fetchEventSource(`/swan/resume-rewriter/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Client-Type": "web",
        accept: "text/event-stream, application/json",
      },
      body: JSON.stringify(params),
      async onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("** [JR] log Connection succeed **", res.statusText);
        } else if (res.status === 429) {
          // TODO: discuss with PM
          notification.error({
            message: "Copilot Rate limit",
          });
          captureExceptionToSentry(
            new Error(`Copilot Error: Rate limit, statusCode: ${res.status}.`),
            {
              extra: {
                params: JSON.stringify(params),
              },
            }
          );
          // throw new Error("Rate limit");
        } else if (res.status >= 400 && res.status < 500) {
          const clientError = new Error(`Copilot Error: Client error, statusCode: ${res.status}.`);
          captureExceptionToSentry(clientError, {
            extra: {
              params: JSON.stringify(params),
            },
          });

          if (onError) {
            onError(clientError);
          }
        } else {
          const serverError = new Error(`Copilot Error: Server error, statusCode: ${res.status}.`);
          captureExceptionToSentry(serverError, {
            extra: {
              params: JSON.stringify(params),
            },
          });

          if (onError) {
            onError(serverError);
          }
        }
      },
      onmessage(event) {
        try {
          const message = event.data;
          const data = JSON.parse(message);
          onData(data);
        } catch (err) {
          const clientError = new Error(`Copilot Error: Client error ${err}, response: ${event}.`);
          captureExceptionToSentry(clientError, {
            extra: {
              params: JSON.stringify(params),
            },
          });

          if (onError) {
            onError(clientError);
          }
          return;
        }
      },
      onclose() {
        // debugger
        if (onClose) {
          onClose();
        }

        resolve("closed");
      },
      onerror: err => {
        const error = new Error(`Copilot Error in onerror: ${err}`);

        captureExceptionToSentry(error, {
          extra: {
            params: JSON.stringify(params),
          },
        });

        if (onError) {
          onError(error);
        }
      },
      // TODO: switch tab, should abort?
      openWhenHidden: true,
    });
  });
};

export const formatIssueStatisticsId = (itemId?: string) => {
  return `${itemId}-issue-statistics`;
};

export const getNotEmptySeverities = (totalIssues?: components["schemas"]["IssueMap"]) => {
  if (!totalIssues) {
    return undefined;
  }

  return Object.entries(totalIssues).reduce((severities, [severity, count]) => {
    if (count > 0) {
      severities.push(severity as ISSUE_SEVERITY);
    }

    return severities;
  }, [] as ISSUE_SEVERITY[]);
};

export const getSeverityIssueMap = ({
  resumeDiagnose,
  severities,
  sectionLayout,
}: {
  resumeDiagnose?: components["schemas"]["ResumeDiagnose"];
  severities?: ISSUE_SEVERITY[];
  sectionLayout?: components["schemas"]["Section"][];
}): Record<number, string[]> => {
  if (!severities || !resumeDiagnose || !sectionLayout) return {};

  try {
    const severityIssueMap = severities.reduce(
      (map, severity) => {
        map[severity] = [];

        return map;
      },
      {} as Record<string, string[]>
    );

    const personalInfo = resumeDiagnose.personalInfo as TPersonalInfo;
    const summary = resumeDiagnose.summary as TSummary;
    const education = resumeDiagnose.education as TEducation;
    const workExperience = resumeDiagnose.workExperience as TWorkExperience;
    const skills = resumeDiagnose.skills as TSkills;

    const checkIssueMapReady = () => {
      return Object.values(severityIssueMap).every(issueIds => {
        return issueIds.length > 0;
      });
    };

    const updateIssueMap = (item: TResumeDiagnoseItem) => {
      if (checkIssueMapReady()) {
        return true;
      }
      if (item.totalIssues?.Urgent && severityIssueMap[ISSUE_SEVERITY.Urgent]) {
        severityIssueMap[ISSUE_SEVERITY.Urgent].push(formatIssueStatisticsId(item.itemId));
      }
      if (item.totalIssues?.Critical && severityIssueMap[ISSUE_SEVERITY.Critical]) {
        severityIssueMap[ISSUE_SEVERITY.Critical].push(formatIssueStatisticsId(item.itemId));
      }
      if (item.totalIssues?.Optional && severityIssueMap[ISSUE_SEVERITY.Optional]) {
        severityIssueMap[ISSUE_SEVERITY.Optional].push(formatIssueStatisticsId(item.itemId));
      }
    };

    const sectionToFindFirstIssue: Record<number, () => true | void> = {
      [RESUME_UPDATE_SECTION.personalInfo]: () => {
        if (checkIssueMapReady()) {
          return true;
        }

        if (personalInfo.diagnoseResultList && !isEmpty(personalInfo.diagnoseResultList)) {
          for (const item of personalInfo.diagnoseResultList) {
            if (!item.totalIssues) {
              continue;
            }
            if (updateIssueMap(item)) {
              return true;
            }
          }
        }
      },
      [RESUME_UPDATE_SECTION.summary]: () => {
        if (checkIssueMapReady()) {
          return;
        }

        if (summary.diagnoseResultList && !isEmpty(summary.diagnoseResultList)) {
          for (const item of summary.diagnoseResultList) {
            if (!item.totalIssues) {
              continue;
            }
            if (updateIssueMap(item)) {
              return;
            }
          }
        }
      },

      [RESUME_UPDATE_SECTION.education]: () => {
        if (checkIssueMapReady()) {
          return;
        }

        if (education.diagnoseResultList && !isEmpty(education.diagnoseResultList)) {
          for (const item of education.diagnoseResultList) {
            if (!item.totalIssues) {
              continue;
            }
            if (updateIssueMap(item)) {
              return;
            }
          }
        }

        if (checkIssueMapReady()) {
          return;
        }

        const educationDetails = education.educationDetails;
        for (const educationDetail of educationDetails) {
          if (educationDetail.diagnoseResultList && !isEmpty(educationDetail.diagnoseResultList)) {
            for (const item of educationDetail.diagnoseResultList) {
              if (!item.totalIssues) {
                continue;
              }
              if (updateIssueMap(item)) {
                return;
              }
            }
          }
        }
      },
      [RESUME_UPDATE_SECTION.workExperience]: () => {
        if (checkIssueMapReady()) {
          return;
        }

        if (workExperience.diagnoseResultList && !isEmpty(workExperience.diagnoseResultList)) {
          for (const item of workExperience.diagnoseResultList) {
            if (!item.totalIssues) {
              continue;
            }
            if (updateIssueMap(item)) {
              return;
            }
          }
        }

        const workExperienceDetails = workExperience.workExperienceDetails;
        for (const workExpr of workExperienceDetails) {
          if (workExpr.diagnoseResultList && !isEmpty(workExpr.diagnoseResultList)) {
            for (const item of workExpr.diagnoseResultList) {
              if (!item.totalIssues) {
                continue;
              }
              if (updateIssueMap(item)) {
                return;
              }
            }
          }

          const jobDescriptions = workExpr.jobDescriptions;

          for (const jobDesc of jobDescriptions) {
            if (jobDesc.diagnoseResultList && !isEmpty(jobDesc.diagnoseResultList)) {
              for (const item of jobDesc.diagnoseResultList) {
                if (!item.totalIssues) {
                  continue;
                }
                if (checkIssueMapReady()) {
                  return;
                }
                if (!workExpr.itemId) {
                  return;
                }
                if (item.totalIssues?.Urgent && severityIssueMap[ISSUE_SEVERITY.Urgent]) {
                  severityIssueMap[ISSUE_SEVERITY.Urgent].push(
                    formatIssueStatisticsId(workExpr.itemId)
                  );
                }
                if (item.totalIssues?.Critical && severityIssueMap[ISSUE_SEVERITY.Critical]) {
                  severityIssueMap[ISSUE_SEVERITY.Critical].push(
                    formatIssueStatisticsId(workExpr.itemId)
                  );
                }
                if (item.totalIssues?.Optional && severityIssueMap[ISSUE_SEVERITY.Optional]) {
                  severityIssueMap[ISSUE_SEVERITY.Optional].push(
                    formatIssueStatisticsId(workExpr.itemId)
                  );
                }
              }
            }
          }
        }
      },
      [RESUME_UPDATE_SECTION.skills]: () => {
        if (checkIssueMapReady()) {
          return;
        }

        if (skills.diagnoseResultList && !isEmpty(skills.diagnoseResultList)) {
          for (const item of skills.diagnoseResultList) {
            if (!item.totalIssues) {
              continue;
            }
            if (updateIssueMap(item)) {
              return;
            }
          }
        }
      },
      [RESUME_UPDATE_SECTION.projects]: () => {},
    };

    const draggableSections = sectionLayout.filter(
      section =>
        section.section !== RESUME_UPDATE_SECTION.personalInfo &&
        section.section !== RESUME_UPDATE_SECTION.summary
    );

    const personalFirstIssueReady = sectionToFindFirstIssue[RESUME_UPDATE_SECTION.personalInfo]();
    if (personalFirstIssueReady) return severityIssueMap;

    const summaryFirstIssueReady = sectionToFindFirstIssue[RESUME_UPDATE_SECTION.summary]();
    if (summaryFirstIssueReady) return severityIssueMap;

    for (const section of draggableSections) {
      const sectionId = section.section;
      if (sectionId === undefined) {
        continue;
      }

      const callback = sectionToFindFirstIssue[sectionId];
      const sectionFirstIssueReady = callback();

      if (sectionFirstIssueReady) {
        return severityIssueMap;
      }
    }

    return severityIssueMap;
  } catch (error) {
    return {};
  }
};

export const getScoreLevel = (score: number) => {
  if (score >= 9) {
    return RESUME_SCORE_LEVEL.EXCELLENT;
  }
  if (score >= 7.6) {
    return RESUME_SCORE_LEVEL.GOOD;
  }
  if (score >= 6.1) {
    return RESUME_SCORE_LEVEL.fAIR;
  }

  return RESUME_SCORE_LEVEL.POOR;
};

export const autoIncreaseSkillGroup = (skills: TSkills["skills"]) => {
  // Step 1: Check if skills has only one key and that key is DEFAULT
  const skillKeys = Object.keys(skills ?? {});

  if (skills && skillKeys.length === 1 && skillKeys[0] === DEFAULT_KEY) {
    // Rename DEFAULT key to Skills
    const defaultValue = skills?.[DEFAULT_KEY];
    delete skills?.[DEFAULT_KEY];
    skills[RENAMED_KEY] = defaultValue;
  }

  // Step 2: Find all keys that start with NEW_SKILL_GROUP_PREFIX
  const skillGroupKeys = Object.keys(skills ?? {}).filter(key =>
    key.startsWith(NEW_SKILL_GROUP_PREFIX)
  );

  // Step 3: Determine the highest number suffix
  let maxSuffix = 0;
  skillGroupKeys.forEach(key => {
    const suffix = parseInt(key.replace(NEW_SKILL_GROUP_PREFIX, ""), 10);
    if (!isNaN(suffix) && suffix > maxSuffix) {
      maxSuffix = suffix;
    }
  });

  const newKey = `${NEW_SKILL_GROUP_PREFIX} ${maxSuffix + 1}`;
  if (skills) {
    skills[newKey] = []; // or any default value you want to assign
  }

  return skills;
};
