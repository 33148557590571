import { createContext, useContext } from "react";
import { isMobile } from "react-device-detect";

export const IsSsrMobileContext = createContext<boolean | undefined>(undefined);

const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext);

  return isSsrMobile || isMobile;
};

export default useIsMobile;
