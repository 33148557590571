import { HTTP_STATUS_CODES } from "@/enums/http";
import { message, notification } from "antd";
import axios from "axios";
import MobileDetect from "mobile-detect";
import { createElement } from "react";

import { isBrowser } from "@/utils";
import { captureExceptionToSentry } from "@/utils/sentry";

if (isBrowser) {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.phone();

  axios.defaults.headers["X-Client-Type"] = isMobile ? "mobile_web" : "web";
}

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // special case for no response.data.success
    if (
      response?.config?.url?.includes("swan/resume/preview") ||
      response?.config?.url?.includes("swan/resume/download") ||
      response?.config?.url?.includes("swan/resume-rewriter/export") ||
      response?.config?.url?.includes("swan/resume-tailor/result") ||
      response?.config?.url?.includes("swan/resume-tailor/export-doc") ||
      response?.config?.url?.includes("swan/resume/pdf-to-doc")
    ) {
      return response;
    }

    // if response.data exists, check if response.data.success is true.
    if (!response?.data?.success) {
      // report as issue to sentry
      captureExceptionToSentry(new Error(`${response?.data?.errorMsg || response?.data?.result}`));

      // special case for api that won't display error notification
      if (
        response?.config?.url?.includes("/auth/edu/verify") ||
        response?.config?.url?.includes("newinfo") ||
        response?.config?.url?.includes("register") ||
        response?.config?.url?.includes("sso") ||
        response?.config?.url?.includes("reset/password") ||
        response?.config?.url?.includes("event") ||
        response?.config?.url?.includes("swan/landing/oneline/parse") ||
        response?.config?.url?.includes("swan/job/ignore") ||
        response?.config?.url?.includes("swan/auth/login/pwd") ||
        response?.config?.url?.includes("swan/auth/edu/email")
      ) {
        return response;
      }

      // show error notification
      if (isBrowser && !response?.config?.url?.includes("swan/resume-tailor/overview")) {
        notification.error({
          message: response?.data?.errorMsg || response?.data?.result,
        });
      }
    }

    return response;
  },
  function (error) {
    if (error?.config?.url?.includes("event")) {
      return;
    }

    if ([HTTP_STATUS_CODES.FORBIDDEN].includes(error?.response?.status)) {
      message.info({
        icon: createElement("div"),
        content: `You've reached the hourly limit, please retry in a few minutes.`,
      });
      return;
    }

    if (
      [HTTP_STATUS_CODES.UNAUTHORIZED, HTTP_STATUS_CODES.BAD_REQUEST].includes(
        error?.response?.status
      )
    ) {
      return error?.response;
    }

    if (
      error?.config?.url?.includes("/auth/edu/verify") ||
      error?.config?.url?.includes("newinfo") ||
      error?.config?.url?.includes("register") ||
      error?.config?.url?.includes("sso") ||
      error?.config?.url?.includes("reset/password") ||
      error?.config?.url?.includes("event") ||
      error?.config?.url?.includes("swan/landing/oneline/parse") ||
      error?.config?.url?.includes("swan/job/ignore") ||
      error?.config?.url?.includes("swan/auth/login/pwd") ||
      error?.config?.url?.includes("swan/auth/edu/email") ||
      error?.config?.url?.includes("swan/resume-tailor/apply")
    ) {
      return error?.response;
    }

    if (
      isBrowser &&
      window &&
      document &&
      !axios.isCancel(error) &&
      axios.isAxiosError(error) &&
      error?.response?.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED
    ) {
      captureExceptionToSentry(error);

      notification.error({
        message:
          "Oops! Something went wrong on our end. Please try again later or contact support@jobright.ai if the issue persists.",
      });
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
