import { BUILD_ENV, RUNTIME_ENV } from "@/enums";
import * as amplitude from "@amplitude/analytics-browser";

import { localStorageUtil } from "./storage";

export interface Analytics {
  setUserId: (id: string) => void;
  trackEvent: (name: string, data?: Record<string, any>) => void;
}

const AMPLITUDE_API_MAP: Record<
  BUILD_ENV,
  {
    apiKey: string;
    replaySampleRate: number;
  }
> = {
  production: {
    apiKey: "bd8bbcb63283abd85e466c2830355a3a",
    replaySampleRate: 0.2,
  },
  preprod: {
    apiKey: "bd8bbcb63283abd85e466c2830355a3a",
    replaySampleRate: 0.2,
  },
  staging: {
    apiKey: "a8f9598d89bb90cc11d962bafcb63b73",
    replaySampleRate: 0,
  },
  development: {
    apiKey: "777a49dcd112ad01c5e32554fe2787f7",
    replaySampleRate: 0,
  },
};

export class AmplitudeAnalytics implements Analytics {
  apiKey: string;
  replaySampleRate: number;
  isInitialized: boolean = false;

  constructor() {
    const AMPLITUDE_API_INFO = AMPLITUDE_API_MAP[process.env.BUILD_ENV || "development"];

    this.apiKey = AMPLITUDE_API_INFO.apiKey;
    this.replaySampleRate = AMPLITUDE_API_INFO.replaySampleRate;
  }

  private shouldBlock() {
    if (!window?.$RUNTIME_ENV || window?.$RUNTIME_ENV === RUNTIME_ENV.LOCAL) {
      if (localStorageUtil.get("amp_debug") === "true") {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  private initialize() {
    if (this.shouldBlock()) return;

    if (!this.isInitialized) {
      amplitude.init(this.apiKey, {
        defaultTracking: true,
        serverUrl: "/api/events",
        transport: "beacon",
        // transport: "xhr",

        // To make sure the event will be scheduled right away.
        flushIntervalMillis: 0,
        flushQueueSize: 1,
        identityStorage: "localStorage",
      });

      this.isInitialized = true;

      // assemble replay
      // const sessionReplayTracking = sessionReplayPlugin({
      //   sampleRate: this.replaySampleRate,
      // });
      // amplitude.add(sessionReplayTracking);
    }
  }

  setUserId(id: string) {
    if (this.shouldBlock()) return;

    this.initialize();
    amplitude.setUserId(id);
  }

  setUserProperty(key: string, value: amplitude.Types.ValidPropertyType) {
    if (this.shouldBlock()) return;

    this.initialize();
    // https://github.com/amplitude/Amplitude-TypeScript/blob/main/examples/browser/next-app/pages/index.tsx
    amplitude.identify(new amplitude.Identify().set(key, value));
  }

  trackEvent(name: string, data?: Record<string, any>) {
    if (this.shouldBlock()) return;

    this.initialize();
    amplitude.track(name, data);
  }
}
