import { RUNTIME_ENV } from "@/enums";

const HOSTNAME_TO_ENV: Record<string, RUNTIME_ENV> = {
  localhost: RUNTIME_ENV.LOCAL,
  "127.0.0.1": RUNTIME_ENV.LOCAL,
  "0.0.0.0": RUNTIME_ENV.LOCAL,
  "dev.jobright-internal.com": RUNTIME_ENV.DEVELOPMENT,
  "jobright-internal.com": RUNTIME_ENV.STAGING,
  "jobright.ai": RUNTIME_ENV.PRODUCTION,
};

if (typeof window !== "undefined") {
  window.$RUNTIME_ENV = HOSTNAME_TO_ENV[window.location.hostname];
  window.$BUILD_ENV = process.env.BUILD_ENV;
}
