import { ISSUE_SEVERITY, ISSUE_TYPE, RESUME_UPDATE_SECTION } from "@/enums/jobs/resume";

export const POLLING_INTERVAL_SECOND_OF_DIAGNOSE = 5;

export const MONTH_FORMAT = "YYYY-MM";
export const DAY_FORMAT = "YYYY-MM-DD";
export const YEAR_FORMAT = "YYYY";

export const ISSUE_LEVEL_2_COLOR = {
  [ISSUE_SEVERITY.Urgent]: {
    edgeBKColor: "rgba(255, 70, 90, 0.50)",
    baseBKColor: "rgba(255, 70, 90, 0.05)",
  },
  [ISSUE_SEVERITY.Critical]: {
    edgeBKColor: "rgba(253, 167, 0, 0.50)",
    baseBKColor: "rgba(253, 167, 0, 0.05)",
  },
  [ISSUE_SEVERITY.Optional]: {
    edgeBKColor: "rgba(20, 200, 240, 0.50)",
    baseBKColor: "rgba(20, 200, 240, 0.05)",
  },
};

export const FIELD_TO_DISPLAY: Record<RESUME_UPDATE_SECTION, Record<string, string>> = {
  [RESUME_UPDATE_SECTION.personalInfo]: {
    name: "Name",
    email: "Email",
    phoneNumber: "Phone",
    location: "Location",
    linkedin: "Linkedin Url",
    githubUrl: "Github Url",
    personalSite: "Other Url",
  },
  [RESUME_UPDATE_SECTION.summary]: {
    summary: "Summary",
  },
  [RESUME_UPDATE_SECTION.education]: {
    organization: "School Name",
    accreditation: "Degree & Majors",
    location: "Location",
    gpa: "GPA",
    startDate: "Start Date",
    completionDate: "End Date",
    courses: "Add Coursework",
    achievements: "Add Achievement",
  },
  [RESUME_UPDATE_SECTION.workExperience]: {
    organization: "Company Name",
    jobTitle: "Job Title",
    jobDescriptions: "Job Description",
    startDate: "Start Date",
    completionDate: "End Date",
    location: "Location",
    summary: "Summary",
  },
  [RESUME_UPDATE_SECTION.skills]: {
    skills: "Skills",
  },
  [RESUME_UPDATE_SECTION.projects]: {
    name: "Project Name",
    location: "Location",
    organization: "Organization",
    startDate: "Start Date",
    completionDate: "End Date",
  },
  [RESUME_UPDATE_SECTION.resumeAttr]: {},
  [RESUME_UPDATE_SECTION.certifications]: {
    name: "Certification Name",
    description: "Description",
  },
  [RESUME_UPDATE_SECTION.achievements]: {
    name: "Achievement Name",
    description: "Description",
  },
  [RESUME_UPDATE_SECTION.languages]: {
    name: "Language",
    description: "Proficiency",
  },
  [RESUME_UPDATE_SECTION.publications]: {
    name: "Publication Name",
    description: "Description",
    authors: "Authors",
  },
  [RESUME_UPDATE_SECTION.extracurricularExperience]: {
    title: "Title",
    startDate: "Start Date",
    completionDate: "End Date",
    organization: "Organization Name",
    // description: "Description",
  },
};

export const RANK_2_DISPLAY: Record<
  string,
  {
    image: string;
    bk: string;
    text: string;
    tagBK: string;
    tooltip: string;
  }
> = {
  A: {
    image: "/newimages/resume/alevel.png",
    bk: "linear-gradient(90deg, #F1FFFC 0%, #FFF 18.84%)",
    text: "EXCELLENT",
    tagBK: "rgba(0, 240, 160, 0.50)",
    tooltip:
      "Your resume is top-notch and stands out in the job market. It showcases your skills and experience effectively, surpassing most applicants.",
  },
  B: {
    image: "/newimages/resume/blevel.png",
    bk: "linear-gradient(90deg, #FFFAEC 0%, #FFF 18.84%)",
    text: "GOOD",
    tagBK: "rgba(253, 197, 0, 0.50)",
    tooltip:
      "Your resume is strong, but there's potential for it to be even better. Fine-tune the details we've suggested to elevate its overall effectiveness.",
  },
  C: {
    image: "/newimages/resume/clevel.png",
    bk: "linear-gradient(90deg, #FFF5F1 0%, #FFF 18.84%)",
    text: "SATISFACTORY",
    tagBK: "rgba(255, 117, 58, 0.50)",
    tooltip:
      "You're on the right track, but there's room for improvement on your resume. Focus on refining and adding details to strengthen your profile.",
  },
  D: {
    image: "/newimages/resume/dlevel.png",
    bk: "linear-gradient(90deg, #FFF4F5 0%, #FFF 18.84%)",
    text: "IMPROVABLE",
    tagBK: "rgba(255, 70, 90, 0.50)",
    tooltip:
      "Your resume is just starting out and needs substantial work. Address the highlighted issues to enhance your content and format, boosting your score significantly.",
  },
};

export const ISSUE_SEVERITY_2_DISPLAY = {
  [ISSUE_SEVERITY.Urgent]: {
    dotColor: "#FF465A",
    issueBKColor: "rgba(255, 70, 90, 0.05)",
    issueBSColor: "4px 0px 0px 0px rgba(255, 70, 90, 0.10) inset",
  },
  [ISSUE_SEVERITY.Critical]: {
    dotColor: "#FDC500",
    issueBKColor: "rgba(253, 197, 0, 0.05)",
    issueBSColor: "4px 0px 0px 0px rgba(255, 199, 0, 0.10) inset",
  },
  [ISSUE_SEVERITY.Optional]: {
    dotColor: "#14C8F0",
    issueBKColor: "rgba(20, 200, 240, 0.05)",
    issueBSColor: "4px 0px 0px 0px rgba(20, 200, 240, 0.05) inset",
  },
};

export const ISSUE_TYPE_2_TITLE: Record<number, string> = {
  [ISSUE_TYPE.LACK_OF_ACTION_VERBS]: "Lack of Action Verbs",
  [ISSUE_TYPE.LACK_OF_METHODOLOGY]: "Methodology Explanation",
  [ISSUE_TYPE.LACK_OF_ACCOMPLISHMENTS]: "Lack of Accomplishment",
  [ISSUE_TYPE.INCORRECT_SPELLING]: "Incorrect Spelling or Grammar",
  [ISSUE_TYPE.FILLER_WORDS]: "Use of Filler words",
  [ISSUE_TYPE.BUZZWORDS]: "Buzzwords",
  [ISSUE_TYPE.BULLET_POINT_TOO_SHORT]: "Bullet point too short",
  [ISSUE_TYPE.BULLET_POINT_TOO_LONG]: "Bullet point too long",

  [ISSUE_TYPE.MISSING_SUMMARY]: "Missing Summary",
  [ISSUE_TYPE.MISSING_FIELD]: "Important Fields Missing",
  [ISSUE_TYPE.SUMMARY_NEEDS_IMPROVEMENT]: "Summary Needs Improvement",
  [ISSUE_TYPE.INSUFFICIENT_RELEVANT_SKILLS]: "Insufficient Relevant Skills",
  [ISSUE_TYPE.IRRELEVANT_EXPERIENCE_TITLE]: "Irrelevant Work Exp. Title",
};

export const ISSUE_TYPE_2_DESC: Record<number, string> = {
  [ISSUE_TYPE.LACK_OF_ACTION_VERBS]:
    "Some of your experiences use passive or generic verbs that fail to highlight your active role and impact in past positions.",
  [ISSUE_TYPE.LACK_OF_METHODOLOGY]:
    "Some of your experience lack the specific approaches you've taken showcases your domain knowledge and expertise.",
  [ISSUE_TYPE.LACK_OF_ACCOMPLISHMENTS]:
    "Some of your experiences lack milestone or quantifiable results to demonstrate the impact of your work.",
  [ISSUE_TYPE.INCORRECT_SPELLING]:
    "Your resume contains a few spelling or grammatical errors that could undermine your professionalism and attention to detail.",
  [ISSUE_TYPE.FILLER_WORDS]:
    "Your resume contains filler words or phrases that do not add value and could be streamlined for greater impact.",
  [ISSUE_TYPE.BUZZWORDS]:
    "Your resume includes overused buzzwords or clichés that may detract from the authenticity and specific achievements of your experiences.",
  [ISSUE_TYPE.BULLET_POINT_TOO_SHORT]:
    "Some bullet points are too brief, lacking sufficient detail to clearly communicate the scope and significance of your achievements.",
  [ISSUE_TYPE.BULLET_POINT_TOO_LONG]:
    "Some bullet points are too lengthy, which may hinder the clarity and effectiveness of your resume's messaging.",
  [ISSUE_TYPE.MISSING_SUMMARY]:
    "Your resume lacks a summary section, which is essential for quickly highlighting your key skills and experiences to employers.",
  [ISSUE_TYPE.MISSING_FIELD]:
    "Your resume lacks a few essential fields for the experience to be complete, a potential hindrance to recruiters assessing your profile.",
  [ISSUE_TYPE.SUMMARY_NEEDS_IMPROVEMENT]:
    "Your current summary does not effectively showcase your qualifications and alignment with the job you are targeting.",
  [ISSUE_TYPE.INSUFFICIENT_RELEVANT_SKILLS]:
    "Your skill section contains fewer than 10 skills, which may not sufficiently showcase your qualifications.",
  [ISSUE_TYPE.IRRELEVANT_EXPERIENCE_TITLE]:
    "The job title(s) in your recent work experiences do not closely align with your target position.",
};

export const INPUT_SHINE_ANIMATION_DURATION = 1500;

export const ANALYSIS_ERROR_CODE = 402;

export const NEW_SKILL_GROUP_PREFIX = "New Skill Group";
export const DEFAULT_KEY = "DEFAULT";
export const RENAMED_KEY = "Skills";
