import { isMobile } from "react-device-detect";

import { postEvent } from "@/services/event";
import { components } from "@/services/schema";

import { captureExceptionToSentry } from "./sentry";

type EventParams = Omit<
  components["schemas"]["FrontEventRequest"],
  "userId" | "timestamp" | "timezoneId" | "browserInfo" | "eventDetail"
>;

const uploadEvent = async (
  params: EventParams & { eventDetail: Record<string, any> },
  options?: {
    transport?: "xhr" | "sendBeacon";
  }
) => {
  const transport = options ? options.transport : "xhr";

  const _params: components["schemas"]["FrontEventRequest"] = {
    timestamp: new Date().getTime(),
    timezoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    browserInfo: {
      userAgent: navigator.userAgent as any,
    },
    eventDetail: {
      ...params.eventDetail,
      platform: isMobile ? "mobile" : ("pc" as any),
    },
    eventType: params.eventType,
    channel: params.channel || "default",
  };

  const eventByXhr = async () => {
    try {
      const res = await postEvent(_params);

      if (!res?.data?.success) {
        throw res?.data?.errorMsg;
      }
    } catch (e) {
      // captureExceptionToSentry(e);
    }
  };

  const eventByBeacon = () => {
    try {
      const blob = new Blob([JSON.stringify(_params)], { type: "application/json" });

      navigator.sendBeacon("/swan/event/submit", blob);
    } catch (e) {
      eventByXhr();
    }
  };
  // @ts-ignore
  if (transport === "sendBeacon" && navigator.sendBeacon) {
    eventByBeacon();

    return;
  }

  eventByXhr();
};

const exports = {
  uploadEvent,
};

export default exports;
