import axios, { AxiosResponse } from "axios";
import { API_HOST } from "@/global";
import { components } from "./schema";

export const postEvent = async (params: components["schemas"]["FrontEventRequest"]) => {
  return axios.post(`${API_HOST}/swan/event/submit`, params) as Promise<
    AxiosResponse<
      components["schemas"]["BaseResponseBoolean"] | components["schemas"]["BaseResponse"]
    >
  >;
};
