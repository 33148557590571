export enum RESUME_DIAGNOSE_STATUS {
  INIT = 0,
  DIAGNOSING = 1,
  FINISHED = 2,
  FAILED = 3,
  EDITED = 4,
  UPDATED = 5,
}

export enum ISSUE_SEVERITY {
  Urgent = "Urgent",
  Critical = "Critical",
  Optional = "Optional",
}

export enum RESUME_UPDATE_SECTION_NAME {
  personalInfo = "personalInfo",
  summary = "summary",
  education = "education",
  workExperience = "workExperience",
  skills = "skills",
  resumeAttr = "resumeAttr",
  projects = "projects",
  certifications = "certifications",
  achievements = "achievements",
  languages = "languages",
  publications = "publications",
  extracurricularExperience = "extracurricularExperience",
}

export enum RESUME_UPDATE_SECTION {
  personalInfo = 2,
  summary = 1,
  education = 3,
  workExperience = 4,
  skills = 5,
  resumeAttr = 6,
  projects = 7,
  certifications = 8,
  achievements = 9,
  languages = 10,
  publications = 11,
  extracurricularExperience = 12,
}

export const RESUME_SECTION_DEFAULT_TITLE_MAP = {
  [RESUME_UPDATE_SECTION.personalInfo]: "Personal Information",
  [RESUME_UPDATE_SECTION.summary]: "Summary",
  [RESUME_UPDATE_SECTION.education]: "Education",
  [RESUME_UPDATE_SECTION.workExperience]: "Work Experience",
  [RESUME_UPDATE_SECTION.skills]: "Skills",
  [RESUME_UPDATE_SECTION.projects]: "Projects",
  [RESUME_UPDATE_SECTION.certifications]: "Certification",
  [RESUME_UPDATE_SECTION.achievements]: "Achievements",
  [RESUME_UPDATE_SECTION.languages]: "Languages",
  [RESUME_UPDATE_SECTION.publications]: "Publications",
  [RESUME_UPDATE_SECTION.resumeAttr]: "resumeAttr",
  [RESUME_UPDATE_SECTION.extracurricularExperience]: "Extracurricular Experience",
};

export const RESUME_SECTION_ID_KEY_FIELD_MAP = {
  [RESUME_UPDATE_SECTION.personalInfo]: "personalInfo",
  [RESUME_UPDATE_SECTION.summary]: "summary",
  [RESUME_UPDATE_SECTION.education]: "education",
  [RESUME_UPDATE_SECTION.workExperience]: "workExperience",
  [RESUME_UPDATE_SECTION.skills]: "skills",
  [RESUME_UPDATE_SECTION.projects]: "projects",
  [RESUME_UPDATE_SECTION.certifications]: "certifications",
  [RESUME_UPDATE_SECTION.achievements]: "achievements",
  [RESUME_UPDATE_SECTION.languages]: "languages",
  [RESUME_UPDATE_SECTION.publications]: "publications",
  [RESUME_UPDATE_SECTION.resumeAttr]: "resumeAttr",
  [RESUME_UPDATE_SECTION.extracurricularExperience]: "extracurricularExperience",
} as const;

export const RESUME_SECTION_KEY_FIELD_ID_MAP = {
  personalInfo: RESUME_UPDATE_SECTION.personalInfo,
  summary: RESUME_UPDATE_SECTION.summary,
  education: RESUME_UPDATE_SECTION.education,
  workExperience: RESUME_UPDATE_SECTION.workExperience,
  skills: RESUME_UPDATE_SECTION.skills,
  projects: RESUME_UPDATE_SECTION.projects,
  certifications: RESUME_UPDATE_SECTION.certifications,
  achievements: RESUME_UPDATE_SECTION.achievements,
  languages: RESUME_UPDATE_SECTION.languages,
  publications: RESUME_UPDATE_SECTION.publications,
  resumeAttr: RESUME_UPDATE_SECTION.resumeAttr,
  extracurricularExperience: RESUME_UPDATE_SECTION.extracurricularExperience,
} as const;

export enum DEFAULT_SECTION_TITLE {
  personalInfo = "personalInfo",
  summary = "Summary",
  education = "Education",
  workExperience = "Work Experience",
  skills = "Skills",
  resumeAttr = "resumeAttr",
  projects = "Projects",
  certifications = "Certification",
  achievements = "Achievement",
  languages = "Language",
  publications = "Publication",
  extracurricularExperience = "Extracurricular Experience",
}

export const RESUME_SECTION_DETAIL_LIST_MAP = {
  [RESUME_UPDATE_SECTION.education]: "educationDetails",
  [RESUME_UPDATE_SECTION.workExperience]: "workExperienceDetails",
  [RESUME_UPDATE_SECTION.skills]: "skills",
  [RESUME_UPDATE_SECTION.projects]: "projectDetails",
  [RESUME_UPDATE_SECTION.certifications]: "certificationDetails",
  [RESUME_UPDATE_SECTION.achievements]: "achievementDetails",
  [RESUME_UPDATE_SECTION.languages]: "languageDetails",
  [RESUME_UPDATE_SECTION.publications]: "publicationDetails",
  [RESUME_UPDATE_SECTION.extracurricularExperience]: "extracurricularExperienceDetails",
};

// export type RESUME_SECTION_DETAIL_LIST_TYPE =
//   | "educationDetails"
//   | "workExperienceDetails"
//   | "skills"
//   | "projectDetails"
//   | "certificationDetails"
//   | "achievementDetails"
//   | "languageDetails"
//   | "publicationDetails";
// // 获取 RESUME_SECTION_DETAIL_LIST_MAP 的 value 类型
// type ValueType =
//   (typeof RESUME_SECTION_DETAIL_LIST_MAP)[keyof typeof RESUME_SECTION_DETAIL_LIST_MAP];

// // 创建一个映射类型，将字符串映射回枚举值
// type ValueToEnumMap = {
//   [K in ValueType]: {
//     [P in keyof typeof RESUME_SECTION_DETAIL_LIST_MAP]: (typeof RESUME_SECTION_DETAIL_LIST_MAP)[P] extends K
//       ? P
//       : never;
//   }[keyof typeof RESUME_SECTION_DETAIL_LIST_MAP];
// };

// // 获取所有 value 对应的枚举值类型
// export type ValueEnumType = ValueToEnumMap[ValueType];

export enum DIAGNOSE_TYPE {
  overall = "Overall",
  bullet = "Bullet",
}

export enum ISSUE_TYPE {
  LACK_OF_ACTION_VERBS = 1, // "Optional"
  LACK_OF_METHODOLOGY = 2, // "Urgent"
  LACK_OF_ACCOMPLISHMENTS = 3, // "Urgent"
  INCORRECT_SPELLING = 4, // "Urgent"
  FILLER_WORDS = 5, // "Optional"
  BUZZWORDS = 6, // "Optional"
  BULLET_POINT_TOO_SHORT = 7, // "Critical"
  BULLET_POINT_TOO_LONG = 8, // "Critical"
  MISSING_SUMMARY = 22, // "Critical"
  MISSING_FIELD = 40, // "Urgent"
  SUMMARY_NEEDS_IMPROVEMENT = 60, // "Urgent"
  INSUFFICIENT_RELEVANT_SKILLS = 61, // "Urgent"
  IRRELEVANT_EXPERIENCE_TITLE = 62, // "Urgent"

  REPETITION = 20, // "Optional"
  NUMBER_OF_BULLET_POINTS = 21, // "Critical"
  RESUME_LENGTH = 30, // "Critical"
  INVALID_FIELD = 41, // "Urgent"
}

export enum RESUME_CHAT_TEMPLATE {
  REWRITE_BULLET_POINT = "REWRITE_BULLET_POINT",
  RESUME_REWRITE_SUMMARY = "RESUME_REWRITE_SUMMARY",
}

export enum RESUME_FEEDBACK_TYPE {
  writer = 0,
  export = 1,
  tailor = 2,
}

export enum RESUME_SCORE_LEVEL {
  EXCELLENT = "Excellent",
  GOOD = "Good",
  fAIR = "Fair",
  POOR = "Poor",
}

export enum REFERRAL_SHARE_TYPE {
  facebook = "facebook",
  twitter = "twitter",
  linkedin = "linkedin",
}

export enum CHANGED_KEYS {
  SUMMARY = "diffSummaryItemId",
  SKILLS = "diffSkillSection",
  RELEVANT_SKILLS = "diffRelevantSkills",
  BULLET_POINTS = "diffBulletPointItemIds",
}

export enum TEMPLATE_ID {
  standard = "Standard",
  compact = "Compact",
}
