import { RECOMMAND_SORTER } from "@/enums/jobs/joblist";
import { API_HOST } from "@/global";
import axios, { AxiosRequestConfig } from "axios";

import { parseResponse } from "@/utils/services";

import { components } from "./schema";
import { JobInfoType } from "./types";

export const fetchJobList = async ({
  refresh = false,
  sortCondition,
  position,
}: {
  refresh: boolean;
  sortCondition: number;
  position?: number;
}) => {
  return axios
    .get(
      `${API_HOST}/swan/recommend/list/jobs?refresh=${refresh}&sortCondition=${sortCondition}${
        position === undefined ? "" : `&position=${position}`
      }
    `
    )
    .then(response => {
      return parseResponse<{
        jobList: JobInfoType[];
        impId: string;
      }>(response);
    });
};

export const fetchLikedJobsV2 = (params: components["schemas"]["EngagedJobListQueryRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponsePaginationResultMapStringObject"]
    >(`${API_HOST}/swan/job/liked/jobs-v2`, params)
    .then(response => {
      if (response?.data?.success) {
        return response?.data?.result;
      }
    });
};

export const fetchAppliedJobsV2 = (params: components["schemas"]["EngagedJobListQueryRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponsePaginationResultMapStringObject"]
    >(`${API_HOST}/swan/job/applied/jobs-v2`, params)
    .then(response => {
      if (response?.data?.success) {
        return response?.data?.result;
      }
    });
};

export const addLikedJob = (params: { jobId: string }) => {
  return axios.post(`${API_HOST}/swan/job/like`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const postUnLikedJob = (params: { jobId: string }) => {
  return axios.post(`${API_HOST}/swan/job/unlike`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const postApplyNewJob = (params: { jobId: string }) => {
  return axios.post(`${API_HOST}/swan/job/apply`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const fetchJobStatistic = () => {
  return axios.post(`${API_HOST}/swan/job/statistic`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const fetchVistorJobList = (
  params: components["schemas"]["VisitorJobListRequest"] & {
    count: number;
    position: number;
  },
  config?: AxiosRequestConfig
) => {
  return axios
    .post(
      `${API_HOST}/swan/recommend/visitor-list/jobs?sortCondition=${
        RECOMMAND_SORTER.RECOMMENDED
      }&count=${String(params.count)}&position=${String(params.position)}`,
      params,
      config
    )
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchSimilarJobList = (
  params: components["schemas"]["SimilarJobListRequest"],
  config?: AxiosRequestConfig
) => {
  return axios.post(`${API_HOST}/swan/recommend/similar/jobs`, params, config).then(response => {
    return parseResponse<
      components["schemas"]["JobListResult"] & {
        jobList: JobInfoType[];
      }
    >(response);
  });
};

export const fetchShareJobDetail = ({ shareId }: { shareId: string }) => {
  return axios.get(`${API_HOST}/swan/share/job/${shareId}`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as {
        jobDetail: JobInfoType;
        logined: boolean;
      };
    }
  });
};

export const generateShareJobLink = ({ jobId }: { jobId: string }) => {
  return axios
    .get(`${API_HOST}/swan/share/job/link`, {
      params: {
        jobId,
      },
    })
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const generateShareJobId = ({ jobId }: { jobId: string }) => {
  return axios
    .get(`${API_HOST}/swan/share/job/id`, {
      params: {
        jobId,
      },
    })
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const generateSocialConnectionEmail = (url: string) => {
  return axios
    .get<components["schemas"]["BaseResponseListString"]>(
      `${API_HOST}/swan/email/linkedin-to-email`,
      {
        params: {
          url,
        },
      }
    )
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      } else {
        return Promise.reject();
      }
    });
};

export const getExternalLinkedinEmail = (url: string) => {
  return axios
    .get<components["schemas"]["BaseResponseEmailFinderResult"]>(
      `${API_HOST}/swan/email/external-linkedin-to-email`,
      {
        params: {
          url,
        },
      }
    )
    .then(res => {
      return parseResponse<components["schemas"]["EmailFinderResult"]>(res);
    });
};

export const postRemoveExpiredJob = ({ jobId }: { jobId: string }) => {
  return axios
    .post(`${API_HOST}/swan/job/unapply`, {
      jobId,
    })
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchJobLikeCount = () => {
  return axios
    .get<components["schemas"]["BaseResponseLikeJobCountResult"]>(`${API_HOST}/swan/job/like/count`)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchJobApplyCount = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseApplyJobCountResult"]
    >(`${API_HOST}/swan/job/apply/count`)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const postNotInterestedJob = (params: components["schemas"]["IgnoreJobRequest"]) => {
  return axios.post(`${API_HOST}/swan/job/ignore`, params).then(res => {
    return res.data as components["schemas"]["BaseResponse"];
  });
};

export const postReportJob = (params: components["schemas"]["ReportJobRequest"]) => {
  return axios.post(`${API_HOST}/swan/job/report`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const postUnlockPersonalConnection = (url: string) => {
  return axios
    .post(`${API_HOST}/swan/resume/update-linkedin-url`, null, {
      params: {
        url,
      },
    })
    .then(response => {
      if (response?.data?.result) {
        return response?.data?.result;
      } else {
        return Promise.reject();
      }
    });
};

export const getUpdateLogs = () => {
  return axios.get(`${API_HOST}/swan/popup/updates`).then(response => {
    return parseResponse<components["schemas"]["UpdatesPopupResult"][]>(response);
  });
};

export const getFeedbackShowFlag = () => {
  return axios.get(`${API_HOST}/swan/feedback/job-match/show`).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const submitMatchFeedback = (params: components["schemas"]["JobMatchFeedbackRequest"]) => {
  return axios.post(`${API_HOST}/swan/feedback/job-match/submit`, params).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const addUserSkill = (params: components["schemas"]["UpdateUserSkillRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/user-skill/add`, params).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const removeUserSkill = (params: components["schemas"]["UpdateUserSkillRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/user-skill/exclude`, params).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const importExternalJob = (params: components["schemas"]["ImportJobRequest"]) => {
  return axios.post(`${API_HOST}/swan/import/job`, params).then(response => {
    return parseResponse<string>(response);
  });
};

export const fetchImportExternalJobStatus = (jobId: string) => {
  return axios.get(`${API_HOST}/swan/import/status?jobId=${jobId}`).then(response => {
    return parseResponse<number>(response);
  });
};

export const fetchExternalJobs = (count: number) => {
  return axios.get(`${API_HOST}/swan/import/list?count=${count}`).then(response => {
    if (response?.data?.success) {
      return response?.data?.result;
    }
  });
};

export const removeExternalJob = (jobId: string) => {
  return axios.post(`${API_HOST}/swan/import/remove?jobId=${jobId}`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const saveApplyStatus = (params: components["schemas"]["SaveApplyStatusRequest"]) => {
  return axios.post(`${API_HOST}/swan/job/apply-status/save`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result;
    }
  });
};

export const fetchAutoApplyJobs = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseJobListResult"]
    >(`${API_HOST}/swan/recommend/auto-apply/jobs`)
    .then(response => {
      return parseResponse<{
        impId: string | null;
        jobList: JobInfoType[];
      }>(response);
    });
};
