export enum COPILOT_SORTER {
  "RECOMMENDED" = "RELEVANCE", // RELEVANCE(0, "RELEVANCE"),
  "MOST_RECENT" = "FRESHNESS", // FRESHNESS(1, "FRESHNESS"),
  "TOP_MATCHED" = "MATCH_SCORE", // MATCH_SCORE(2, "MATCH_SCORE"),
}

export enum COPILOT_CHAT_TEMPLATE {
  ASK_ORION_START = "ASK_ORION_START",
  ENTER_JOB_DETAIL = "ENTER_JOB_DETAIL",
  WELCOME = "WELCOME",
  JOB_HIGHLIGHTS = "JOB_HIGHLIGHTS",
  SOCIAL_CONNECTIONS = "SOCIAL_CONNECTIONS",
  RESUME_TIPS = "RESUME_TIPS",
  USER_INPUT = "USER_INPUT",
  SEARCH_NOTICE = "SEARCH_NOTICE",
  SEARCH_SUGGESTION = "SEARCH_SUGGESTION",
  WRITE_COVER_LETTER = "USER_INPUT",
  SKILL_SUGGESTION = "SKILL_SUGGESTION",
  EXTERNAL_JOB_CHECK_SUGGESTION = "EXTERNAL_JOB_CHECK_SUGGESTION",
}

export enum COPILOT_PRESET_TYPE {
  JOB_HIGHLIGHTS = "JOB_HIGHLIGHTS",
  RESUME_TIPS = "RESUME_TIPS",
  LIST_RESUME_TIPS = "LIST_RESUME_TIPS",
  SOCIAL_CONNECTIONS = "SOCIAL_CONNECTIONS",
  TOP_CANDIDATES = "TOP_CANDIDATES",
  TAIL_RESUME = "TAIL_RESUME",
  WRITE_COVER_LETTER = "WRITE_COVER_LETTER",
}

export enum COPILOT_RENDER_TYPE {
  MESSAGE = "message",
  STREAM_MESSAGE = "stream_message",
  PRESET_OPTION = "preset_option",
  UPDATE_FILTER = "update_filter",
  SHOW_FILTER = "show_filter",
  SOCIAL_CONNECTIONS = "social_connections",
  SORTER = "sorter",
  COMFIRM = "confirm",
  QUICK_GUIDE = "quick_guide",
  FAILURE = "failure",
  VISITOR_WELCOME_MESSAGE = "visitor_welcome_message",
  VISITOR_PRESET_OPTION = "visitor_preset_option",
  VISITOR_SIMILAR_JOBS = "visitor_similar_jobs",
  VISITOR_HOT_JOBS = "visitor_hot_jobs",
  SEARCH_SUGGESTION = "search_suggestion",
  UPDATE_SALARY = "update_salary",
  UPDATE_JOB_SOURCE = "update_job_source",
  EMAIL_CONTACT_INFO = "email_contact_info",
  EXTERNAL_LINKEDIN_EMAIL_INFO = "external_linkedin_email_info",
  ADD_EXTERNAL_JOB = "add_external_job",
  EXTERNAL_JOB_CHECK_SUGGESTION = "external_job_check_suggestion",
}
