import { API_HOST } from "@/global";
import axios from "axios";

import { parseResponse } from "@/utils/services";

import { components } from "./schema";
import { JobTitleCategoryTreeListType } from "./types";

export const updatePreferences = (params: components["schemas"]["FilterRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/update/filter`, params, {});
};

export const updatePreferencesV2 = (params: components["schemas"]["FilterAndCandidateRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/update/filter-v2`, params, {});
};

export const fetchPreferenceTags = () => {
  return axios.post(`${API_HOST}/swan/filter/get/filter`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["FilterResult"];
    }
  });
};

export const fetchIndustries = () => {
  return axios.get(`${API_HOST}/swan/filter/support/industries`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["IndustryResult"];
    }
  });
};

export const fetchOpenCities = () => {
  return axios.get(`${API_HOST}/swan/filter/open/cities`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["BaseResponseListString"]["result"];
    }
  });
};

export const fetchCitySuggestion = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseListString"]
    >(`${API_HOST}/swan/filter/suggestion/cities`, params)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchSuggestedConditionsV2 = () => {
  return axios.post(`${API_HOST}/swan/filter/get/suggested-conditions-v2`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["SuggestedConditionsResultV2"];
    }
  });
};

export const fetchSuggestionTitleListV2 = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseListString"]
    >(`${API_HOST}/swan/filter/suggestion/titles-v2`, params)
    .then(res => {
      if (res?.data?.success) {
        return res?.data?.result;
      }
    });
};

export const fetchSuggestionSkillList = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/suggestion/skills`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["BaseResponseListString"]["result"];
    }
  });
};

export const fetchSuggestionIndustries = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/suggestion/industries-v2`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["BaseResponseListString"]["result"];
    }
  });
};

export const fetchSuggestionCompanies = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios.post(`${API_HOST}/swan/filter/suggestion/companies`, params).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as components["schemas"]["EsCompanyResult"][];
    }
  });
};

export const postUserResumeUpload = (formData: FormData, isOnboarding: boolean = true) => {
  return axios.post<components["schemas"]["BaseResponseResumeResult"]>(
    `${API_HOST}/swan/resume/upload?isOnboarding=${isOnboarding}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const fetchJobTitleFullTreeList = () => {
  return axios.get(`${API_HOST}/swan/filter/support/titles`).then(res => {
    if (res?.data?.success) {
      return res?.data?.result as JobTitleCategoryTreeListType;
    }
  });
};

export const fetchJobTitleListV3 = (params: components["schemas"]["SuggestionRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseListEsTitleResult"]
    >(`${API_HOST}/swan/filter/suggestion/titles-v3`, params)
    .then(res => {
      return parseResponse(res) as components["schemas"]["BaseResponseListEsTitleResult"]["result"];
    });
};
