import { AxiosResponse } from "axios";

import { components } from "../services/schema";

export const parseResponse = <T>(
  res: AxiosResponse<
    Omit<components["schemas"]["BaseResponse"], "result"> & {
      result?: any;
    },
    any
  >
): T | Promise<never> => {
  if (res?.data?.success) {
    return res?.data?.result;
  }

  return Promise.reject(res?.data?.errorMsg);
};
