export const ONBOARDING_V3_SIGNUP_PATHNAME = "/onboarding-v3/signup";
export const ONBOARDING_V3_MODE_SELECTION_PATHNAME = "/onboarding-v3/mode-selection";
export const ONBOARDING_V3_DIAGNOSTICS_PATHNAME = "/onboarding-v3/diagnostics";
export const ONBOARDING_V3_RESUME_PATHNAME = "/onboarding-v3/resume-upload";
export const ONBOARDING_V3_CAREER_GOAL_PATHNAME = "/onboarding-v3/career-goal";
export const ONBOARDING_V3_ADVANCED_PREFERENCES_PATHNAME = "/onboarding-v3/advanced-preferences";

export const LANDING_PATHNAME = "/";
export const H1B_LANDING_PATHNAME = "/h1b-jobs";
export const RESUME_LANDING_PATHNAME = "/ai-resume-builder";
export const RESET_PASSWORD_PATHNAME = "/reset-password";
export const JOB_REFERRAL_PATHNAME = "/job-referral";
export const COPILOT_ORION_LANDING_PATHNAME = "/orion-copilot";
export const JOB_MATCHING_PATHNAME = "/ai-job-match";
export const ENTRY_LEVEL_JOBS = "/entry-level-jobs";

export const JOBS_PROFILE_PATHNAME = "/jobs/profile";
export const JOBS_PROFILE_PATHNAME_V2 = "/jobs/new-profile";

export const JOBS_RESUME_PATHNAME = "/jobs/resume";

export const JOBS_INFO_PATHNAME_PREFIX = "/jobs/info";
export const JOBS_INFO_PATHNAME = "/jobs/info/[id]";
export const VISITOR_PATHNAME = "/jobs/[visit]";

export const VISITOR_SEARCH_PATHNAME = "/jobs/search";

export const ABOUT_PATHNAME = "/about";
export const BLOG_PATHNAME = "https://jobright.ai/blog/";
export const INTERN_LIST_PATHNAME = "https://intern-list.com/";
export const NEW_GRAD_PATHNAME = "https://newgrad-jobs.com/";
export const JOBRIGHT_FOR_GOOD = "https://climate.stripe.com/tjsMYR";

export const LINKEDIN_101_GUIDE = "https://jobright.ai/blog/category/linkedin/";
export const UNLIMATE_GUIDE_TO_H1B = "https://jobright.ai/blog/category/h1b/";
export const LAND_TOP_INTERNSHIP = "https://jobright.ai/blog/category/internship/";

export const MASTERING_INTERVIEWS = "https://jobright.ai/blog/category/interview/";
export const ENERGIZE_YOUR_JOB_SEARCH = "https://jobright.ai/blog/category/job-search/";
export const HOW_TO_WRITE_A_RESUME = "https://jobright.ai/blog/category/resume/";
export const HOW_TO_GET_A_INTERNSHIP = "https://jobright.ai/blog/category/internship/";

export const PRIVACY_POLICY_PATHNAME = "/legal/privacy";
export const TERMS_OF_SERVICE_PATHNAME = "/legal/service";

export const REFERRAL_POLICY_PATHNAME = "/referral-policy";

export const TOOLS_RESUME_MAKER_PATHNAME = "/tools/";

export const ENTERPRISE_PATHNAME = "/enterprise";

export const AI_JOB_ASSISTANT = "/tools/ai-job-assistant";
export const AI_COVER_LETTER_GENERATOR = "/tools/cover-letter-generator";
export const AI_RESUME_HELPER = "/tools/resume-helper";
export const AI_JOB_TRACKER = "/tools/job-tracker";
export const TOOLS_ASSEMBLE = "/tools";

export const ENTERPRISE_INVITE_PATHNAME = "/employers";

export const MOBILE_APP = "/mobile-app";
