import {
  PROFILE_CURRENT_STAGE,
  QUERY_KEYS,
  USER_CLIENT_TYPES,
  USER_STORAGE_KEY,
  USER_VISIT_STATE,
} from "@/enums";
import { useReferralStore } from "@/store/referral";
import { useResumeStore } from "@/store/resume";
import * as Sentry from "@sentry/nextjs";
import { usePrevious, useRequest } from "ahooks";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";

import { components } from "@/services/schema";
import { fetchUserProfileV2 } from "@/services/user";

import { analytics, trackEvent } from "@/utils";
import internalEventTracker from "@/utils/events";
import { captureExceptionToSentry } from "@/utils/sentry";
import { localStorageUtil } from "@/utils/storage";

import { JR_USER_ID_EDGE_CASE, PROFILE_STATE_LINK_ENUM } from "@/constants";
import {
  ABOUT_PATHNAME,
  COPILOT_ORION_LANDING_PATHNAME,
  ENTERPRISE_INVITE_PATHNAME,
  ENTERPRISE_PATHNAME,
  ENTRY_LEVEL_JOBS,
  H1B_LANDING_PATHNAME,
  JOBS_INFO_PATHNAME_PREFIX,
  JOB_MATCHING_PATHNAME,
  JOB_REFERRAL_PATHNAME,
  LANDING_PATHNAME,
  MOBILE_APP,
  ONBOARDING_V3_SIGNUP_PATHNAME,
  REFERRAL_POLICY_PATHNAME,
  RESET_PASSWORD_PATHNAME,
  RESUME_LANDING_PATHNAME,
  TOOLS_ASSEMBLE,
  TOOLS_RESUME_MAKER_PATHNAME,
  VISITOR_PATHNAME,
} from "@/constants/pathname";

export type ProfileType =
  | components["schemas"]["BaseResponseUserProfileResult"]
  | components["schemas"]["BaseResponse"]
  | undefined;

export type ProfileContextType = {
  profile: ProfileType;
  loading: boolean;
  refreshProfile: () => void;
  refreshProfileAsync: typeof fetchUserProfileV2;
  mutateProfile: (data?: any) => void;
  runProfile: (isOnboarding: boolean) => void;
  showTouring: boolean;
  setShowTouring: Dispatch<SetStateAction<boolean>>;
};

export const ProfileContext = createContext<ProfileContextType>({
  profile: undefined,
  loading: false,
  refreshProfile: () => {},
  mutateProfile: () => {},
  refreshProfileAsync: fetchUserProfileV2,
  runProfile: (isOnboarding: boolean) => {},
  showTouring: false,
  setShowTouring: () => {},
});

const ProfileContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const [showTouring, setShowTouring] = useState(false);

  const setTestVersion = useReferralStore(state => state.setTestVersion);

  const [
    setShowResumeWriterFeedbackPopup,
    setShowResumeExportFeedbackPopup,
    setShowResumeTailorFeedbackPopup,
  ] = useResumeStore(store => [
    store.setShowResumeWriterFeedbackPopup,
    store.setShowResumeExportFeedbackPopup,
    store.setShowResumeTailorFeedbackPopup,
  ]);

  const {
    data,
    loading,
    refresh,
    refreshAsync,
    run: runProfile,
    mutate: mutateProfile,
  } = useRequest(isOnboarding => fetchUserProfileV2(), {
    refreshDeps: [router.pathname],
    onBefore: params => {
      if (!!params?.[0]) {
        internalEventTracker.uploadEvent({
          channel: "default",
          eventType: "debug_onboarding_profile_refresh_before",
          eventDetail: data?.data?.result ?? {},
        });
      }
    },
    onSuccess: (newResponse, params) => {
      if (
        newResponse?.data?.result?.logined &&
        newResponse?.data?.result?.currentStage === PROFILE_CURRENT_STAGE.FILTE_RESUME_READY
      ) {
        const userId = String(newResponse?.data?.result?.userId);
        const registerTime = String(newResponse?.data?.result?.registerTime);
        setTestVersion(userId, registerTime);
      }

      if (!!params?.[0]) {
        internalEventTracker.uploadEvent({
          channel: "default",
          eventType: "debug_onboarding_profile_refresh_after",
          eventDetail: newResponse?.data?.result ?? {},
        });
      }
    },
  });

  const prevStage = usePrevious(data?.data?.result?.currentStage);

  useEffect(() => {
    if (
      data?.data?.result?.currentStage === PROFILE_CURRENT_STAGE.FILTE_RESUME_READY &&
      prevStage === PROFILE_CURRENT_STAGE.RESUME_PARSING &&
      data?.data?.result?.isNew === USER_VISIT_STATE.list_never_reached
    ) {
      trackEvent("onboard_success", {
        utm_campaign: (localStorageUtil.get(QUERY_KEYS.UTM_CAMPAIGN) as string) ?? "",
        utm_source: (localStorageUtil.get(QUERY_KEYS.UTM_SOURCE) as string) ?? "",
      });
    }
  }, [data?.data?.result?.currentStage, data?.data?.result?.isNew, prevStage]);

  useEffect(() => {
    if (!router) return;

    if (!data || !data?.data || !data?.data?.result) return;

    // when user has logined, set userId in amplitude, sentry, localStorage
    if (data?.data?.result?.logined) {
      if (data?.data?.result?.userId) {
        analytics.setUserId(data?.data?.result?.userId);
      } else {
        captureExceptionToSentry(new Error("response empty userId"));
      }

      if (data?.data?.result?.email) {
        analytics.setUserProperty("email", data?.data?.result?.email);
        Sentry.setTag("jr_email", data?.data?.result?.email);
      }

      // track jr_userid setup in amplitude by sentry.
      const JRuserId = data?.data?.result?.userId || JR_USER_ID_EDGE_CASE["LOGINED_NOT_JR_USER"];

      // save state in localStorage
      localStorageUtil.set(USER_STORAGE_KEY.USER_ID, JRuserId);

      Sentry.setTag("jr_userid", JRuserId);
    } else {
      // save state in localStorage
      localStorageUtil.set(USER_STORAGE_KEY.USER_ID, JR_USER_ID_EDGE_CASE["NOT_LOGINED"]);
      Sentry.setTag("jr_userid", JR_USER_ID_EDGE_CASE["NOT_LOGINED"]);
    }

    // when user not logined, pages allowlist
    if (!data?.data?.result.logined) {
      // user in landing page, stay
      if (
        [LANDING_PATHNAME, H1B_LANDING_PATHNAME, RESUME_LANDING_PATHNAME].includes(router?.pathname)
      ) {
        return;
      }

      const NO_REDIRECT_PATHNAME_PREFIXES = [
        "/legal",
        "/404",
        "/500",
        RESET_PASSWORD_PATHNAME,
        JOBS_INFO_PATHNAME_PREFIX,
        ABOUT_PATHNAME,
        VISITOR_PATHNAME,
        ONBOARDING_V3_SIGNUP_PATHNAME,
        JOB_MATCHING_PATHNAME,
        JOB_REFERRAL_PATHNAME,
        COPILOT_ORION_LANDING_PATHNAME,
        ENTRY_LEVEL_JOBS,
        TOOLS_RESUME_MAKER_PATHNAME,
        ENTERPRISE_PATHNAME,
        TOOLS_ASSEMBLE,
        REFERRAL_POLICY_PATHNAME,
        ENTERPRISE_INVITE_PATHNAME,
        MOBILE_APP,
      ];

      const shouldNoRedirect = NO_REDIRECT_PATHNAME_PREFIXES.some(pathname => {
        return router?.pathname.startsWith(pathname);
      });

      if (shouldNoRedirect) {
        return;
      }

      router.push(LANDING_PATHNAME);
      return;
    }

    if (
      data?.data?.result.logined &&
      data?.data?.result?.clientTypes &&
      !(data?.data?.result?.clientTypes as Array<any>).includes(USER_CLIENT_TYPES.web)
    ) {
      setShowTouring(true);
    }

    // when user logined
    // NOTE: no currentStage, stay
    if (!data?.data?.result?.currentStage) {
      return;
    }

    const targetRouter = PROFILE_STATE_LINK_ENUM[data?.data?.result?.currentStage as number];

    // NOTE: no matched link, stay
    if (!targetRouter) {
      return;
    }

    // NOTE: avoid redirect loop
    if (router.pathname === targetRouter) {
      return;
    }

    // NOTE: if user reach /jobs/* page, but currentStage is not /jobs/*, redirect according to currentStage
    if (
      router?.pathname.startsWith("/jobs") &&
      router?.pathname !== VISITOR_PATHNAME &&
      !targetRouter?.startsWith("/jobs")
    ) {
      router.push({
        pathname: targetRouter,
        query: router?.query,
      });
      return;
    }

    // NOTE: if user reach onboarding page, redirect according to currentStage (Special treatment: mobile welcome page)
    if (
      [LANDING_PATHNAME, H1B_LANDING_PATHNAME, RESUME_LANDING_PATHNAME].includes(
        router?.pathname
      ) ||
      router?.pathname.startsWith("/onboarding-v3")
    ) {
      router.push({
        pathname: targetRouter,
        query: router?.query,
      });
    }
  }, [data, router]);

  useEffect(() => {
    if (data?.data.result?.showResumeWriterFeedbackPopup !== undefined) {
      setShowResumeWriterFeedbackPopup(data?.data.result?.showResumeWriterFeedbackPopup);
    }

    if (data?.data.result?.showResumeExportFeedbackPopup !== undefined) {
      setShowResumeExportFeedbackPopup(data?.data.result?.showResumeExportFeedbackPopup);
    }

    if (data?.data.result?.showResumeTailorFeedbackPopup !== undefined) {
      setShowResumeTailorFeedbackPopup(data?.data.result?.showResumeTailorFeedbackPopup);
    }
  }, [
    data?.data.result?.showResumeExportFeedbackPopup,
    data?.data.result?.showResumeWriterFeedbackPopup,
    data?.data.result?.showResumeTailorFeedbackPopup,
    setShowResumeWriterFeedbackPopup,
    setShowResumeExportFeedbackPopup,
    setShowResumeTailorFeedbackPopup,
  ]);

  return (
    <ProfileContext.Provider
      value={{
        profile: data?.data,
        loading,
        refreshProfile: refresh,
        mutateProfile,
        refreshProfileAsync: refreshAsync,
        runProfile,
        showTouring,
        setShowTouring,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

const useProfileContext = () => useContext(ProfileContext);

export { ProfileContextProvider, useProfileContext };
