import { APPLIED_JOB_STATUS } from "@/enums/jobs/joblist";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { components } from "@/services/schema";
import { JobInfoType } from "@/services/types";

import { APPLIED_JOB_STATUS_TO_DISPLAY } from "@/constants/jobs/joblist";

import { isEmpty } from "./lang";

dayjs.extend(relativeTime);

export const getApplyTime = (data: JobInfoType) => {
  if (data.applyStatus > 0) {
    return `Marked as "${APPLIED_JOB_STATUS_TO_DISPLAY[data.applyStatus]}" ${dayjs().to(
      dayjs(data?.modifiedTime)
    )}`;
  }

  return `Applied ${dayjs().to(dayjs(data?.applyTime))}`;
};

const convertDate = (date: string = "", isCurrent?: boolean) => {
  if (isCurrent || date?.toLowerCase() === "present") {
    return "Present";
  }

  if (date?.length === 10 || date?.length === 7) {
    return dayjs(date).format("MMM YYYY");
    // } else if (date.length === 7) {
    // return dayjs(date).format("YYYY-MM");
  }

  return date;
};

export const getResumeDateRange = ({
  startDate,
  completionDate,
  isCurrent,
}: components["schemas"]["ResumeDates"]) => {
  const left = convertDate(startDate, false);
  const right = convertDate(completionDate, isCurrent);

  if (isEmpty(left) && isEmpty(right)) {
    return "";
  } else if (isEmpty(left) || isEmpty(right)) {
    return left || right;
  } else {
    return `${left} - ${right}`;
  }
};
