import { EVENT_CHANNEL } from "@/enums/event";
import { COPILOT_SORTER } from "@/enums/jobs/copilot";
import { APPLIED_JOB_STATUS, RECOMMAND_SORTER } from "@/enums/jobs/joblist";

import {
  fetchAppliedJobsV2,
  fetchJobList,
  fetchLikedJobsV2,
  fetchVistorJobList,
} from "@/services/jobs";

import { VISITOR_PATHNAME } from "../pathname";

export const RECOMMEND_PATHNAME = "/jobs/recommend";
export const LIKED_PATHNAME = "/jobs/liked";
export const APPLIED_PATHNAME = "/jobs/applied";
export const EXTERNAL_PATHNAME = "/jobs/external";
export const DETAIL_PATHNAME = "/jobs/info";

export const ROUTE_TO_CHANNEL: Record<string, EVENT_CHANNEL> = {
  [RECOMMEND_PATHNAME]: EVENT_CHANNEL.reco,
  [LIKED_PATHNAME]: EVENT_CHANNEL.liked,
  [APPLIED_PATHNAME]: EVENT_CHANNEL.applied,
  [EXTERNAL_PATHNAME]: EVENT_CHANNEL.external,
};

export const PATH_API_MAP: Record<string, (params: any) => Promise<any>> = {
  [RECOMMEND_PATHNAME]: fetchJobList,
  [LIKED_PATHNAME]: fetchLikedJobsV2,
  [APPLIED_PATHNAME]: fetchAppliedJobsV2,
  [VISITOR_PATHNAME]: fetchVistorJobList,
};

export const TRACKING_SCENE_MAP = {
  [RECOMMEND_PATHNAME]: "reco",
  [LIKED_PATHNAME]: "liked",
  [APPLIED_PATHNAME]: "applied",
};
export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_RECOMMEND_SORTER = RECOMMAND_SORTER.RECOMMENDED;

export const COPILOT_SORTER_TO_RECOMMEND_SORTER: Record<COPILOT_SORTER, RECOMMAND_SORTER> = {
  [COPILOT_SORTER.MOST_RECENT]: RECOMMAND_SORTER.MOST_RECENT,
  [COPILOT_SORTER.RECOMMENDED]: RECOMMAND_SORTER.RECOMMENDED,
  [COPILOT_SORTER.TOP_MATCHED]: RECOMMAND_SORTER.TOP_MATCHED,
};

export const COPILOT_SORTER_TO_DISPLAY: Record<COPILOT_SORTER, string> = {
  [COPILOT_SORTER.MOST_RECENT]: "Most Recent",
  [COPILOT_SORTER.RECOMMENDED]: "Recommended",
  [COPILOT_SORTER.TOP_MATCHED]: "Top Matched",
};

export const RECOMMEND_SORTER_TO_DISPLAY: Record<RECOMMAND_SORTER, string> = {
  [RECOMMAND_SORTER.MOST_RECENT]: "Most Recent",
  [RECOMMAND_SORTER.RECOMMENDED]: "Recommended",
  [RECOMMAND_SORTER.TOP_MATCHED]: "Top Matched",
};

export const APPLY_JOB_ID_KEY = "applyJobId";
export const REFRESH_THRESHOLD = 8;

export const APPLIED_JOB_STATUS_TO_DISPLAY = {
  [APPLIED_JOB_STATUS.APPLIED]: "Applied",
  [APPLIED_JOB_STATUS.INTERVIEWING]: "Interviewing",
  [APPLIED_JOB_STATUS.OFFER_RECEIVED]: "Offer Received",
  [APPLIED_JOB_STATUS.REJECTED]: "Rejected",
  [APPLIED_JOB_STATUS.ARCHIVED]: "Archived",
};
