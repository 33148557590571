import { RESUME_DIAGNOSE_STATUS } from "@/enums/jobs/resume";
import { AxiosError } from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import {
  getDiagnoseResult,
  postCancelDiagnose,
  postResumeItemAdd,
  postResumeItemDelete,
  postResumeUpdate,
  postStartDiagnose,
} from "@/services/resume";
import { components } from "@/services/schema";

import { delay } from "@/utils/delay";
import { getNotEmptySeverities, getSeverityIssueMap } from "@/utils/jobs/resume";
import { captureExceptionToSentry } from "@/utils/sentry";

import { ANALYSIS_ERROR_CODE } from "../constants/jobs/resume";

interface ResumeStore {
  currentDiagnoseId?: string;
  currentResumeId?: string;

  popupResumeDiagnose?: components["schemas"]["ResumeDiagnose"];
  resumeDiagnose?: components["schemas"]["ResumeDiagnose"];
  resumeName?: string;
  resumeTargetJobTitle?: string | null;
  resumeIsPrimary?: boolean | null;
  resumeIsMocking?: boolean | null;
  resumeGlobalStatus?: components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]["result"];
  refreshResumeCollection: null | (() => void);
  showGuidance: boolean;
  setShowGuidance: (show: boolean) => void;

  diagnoseResultLoading: boolean;

  diagnoseStatus: components["schemas"]["ResumeDiagnoseStatusResult"];
  setDiagnoseStatus: (diagnoseStatus: components["schemas"]["ResumeDiagnoseStatusResult"]) => void;

  fetchDiagnoseResult: (diagnoseId: string) => Promise<void>;
  fetchPopupDiagnoseResult: (diagnoseId: string) => Promise<void>;
  startDiagnosing: (
    diagnoseId: string,
    isReDiagnose?: boolean
  ) => Promise<void | typeof ANALYSIS_ERROR_CODE>;
  cancelDiagnose: () => Promise<void>;
  resumeChanging: boolean;

  updateResumeAsync: (params: components["schemas"]["ResumeUpdateItemRequest"]) => Promise<void>;
  addResumeItemAsync: (params: components["schemas"]["ResumeAddItemRequest"]) => Promise<void>;
  deleteResumeItemAsync: (
    params: components["schemas"]["ResumeDeleteItemRequest"]
  ) => Promise<void>;

  inValidModalOpen: boolean;
  setInValidModalOpen: (open: boolean) => void;

  setPersonalInfo: (options: components["schemas"]["PersonalInfo"]) => void;
  setSummary: (summary: string) => void;
  setEducation: (education: components["schemas"]["Education"]) => void;
  setWorkExprience: (workExperience: components["schemas"]["WorkExperience"]) => void;
  setSkills: (skills: components["schemas"]["Skill"]["skills"]) => void;
  setProjects: (projects: components["schemas"]["Project"]) => void;
  setCertifications: (certifications: components["schemas"]["Certification"]) => void;
  setLanguages: (languages: components["schemas"]["Language"]) => void;
  setPublications: (publications: components["schemas"]["Publication"]) => void;
  setExtracurricular: (extracurricular: components["schemas"]["Extracurricular"]) => void;
  setAchievements: (achievements: components["schemas"]["Achievement"]) => void;

  diagnoseErrorOpen: boolean;
  setDiagnoseErrorOpen: (open: boolean) => void;
  diagnosingOpen: boolean;
  setDiagnosingOpen: (open: boolean) => void;
  diagnoseDiffOpen: boolean;
  setDiagnoseDiffOpen: (open: boolean) => void;
  reportOpen: boolean;
  setReportOpen: (open: boolean) => void;
  sectionLayout?: components["schemas"]["Section"][];
  setSectionLayout: (sectionLayout: components["schemas"]["Section"][]) => void;

  isReDiagnose: boolean;
  reset: () => void;

  resumeTourOpen: boolean;
  setResumeTourOpen: (open: boolean) => void;
  tourStep: number;
  setTourStep: (step: number) => void;

  severityIssueMap: Record<string, string[]>;
  setSeverityIssueMap: (severityIssueMap: Record<string, string[]>) => void;

  showResumeWriterFeedbackPopup: boolean;
  setShowResumeWriterFeedbackPopup: (show: boolean) => void;
  showResumeExportFeedbackPopup: boolean;
  setShowResumeExportFeedbackPopup: (show: boolean) => void;
  showResumeTailorFeedbackPopup: boolean;
  setShowResumeTailorFeedbackPopup: (show: boolean) => void;
  writerFeedbackPopupOpen: boolean;
  setWriterFeedbackPopupOpen: (show: boolean) => void;
  exportFeedbackPopupOpen: boolean;
  setExportFeedbackPopupOpen: (show: boolean) => void;
  resumeInfoEditPopupOpen: boolean;
  setResumeInfoEditPopupOpen: (open: boolean) => void;

  showMobileUseResume: boolean | undefined;
  setShowMobileUseResume: (show: boolean) => void;

  showCreateNewResumePopup: boolean;
  setShowCreateNewResumePopup: (show: boolean) => void;

  currentPollingResumeId: string | null;
  setCurrentPollingResumeId: (id: string | null) => void;

  setResumeIsMocking: (isMocking: boolean | null) => void;
  setResumeTargetJobTitle: (title: string | null) => void;
  setResumeIsPrimary: (isPrimary: boolean | null) => void;
  setResumeName: (name: string | null) => void;
  setResumeDiagnose: (diagnose: components["schemas"]["ResumeDiagnose"] | null) => void;
  setResumeId: (id: string | null) => void;
  setResumeGlobalStatus: (
    status: components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]["result"]
  ) => void;
  setResumeInitialData: (
    data: components["schemas"]["BaseResponseResumeDiagnoseResult"]["result"],
    diagnoseId: string
  ) => void;
  resetCurrentPageResume: () => void;
  setPopupResumeDiagnose: (diagnose: components["schemas"]["ResumeDiagnose"]) => void;
  setRefreshResumeCollection: (func: () => void) => void;
}

const initialResumeState = {
  currentDiagnoseId: undefined,
  resumeDiagnose: undefined,
  sectionLayout: undefined,
  resumeName: "",
  diagnoseStatus: {},
  diagnoseResultLoading: false,
  showGuidance: false,
  resumeChanging: false,
  inValidModalOpen: false,
  diagnoseErrorOpen: false,
  diagnosingOpen: false,
  diagnoseDiffOpen: false,
  reportOpen: false,
  isReDiagnose: false,
  resumeTourOpen: false,
  tourStep: 0,
  severityIssueMap: {},
  showResumeWriterFeedbackPopup: false,
  showResumeExportFeedbackPopup: false,
  showResumeTailorFeedbackPopup: false,
  writerFeedbackPopupOpen: false,
  exportFeedbackPopupOpen: false,
  resumeInfoEditPopupOpen: false,
  showMobileUseResume: false,

  resumeRealDiagnoseStatus: false,
  showCreateNewResumePopup: false,
  resumeIsMocking: null,
  refreshResumeCollection: () => {},

  currentPollingResumeId: null,
  setCurrentPollingResumeId: () => {},
};

export const useResumeStore = create<ResumeStore>()(
  devtools(
    (set, get) => ({
      ...initialResumeState,
      reset: () => {
        set({
          ...initialResumeState,
        });
      },
      setShowGuidance: show => {
        set({ showGuidance: show });
      },
      setDiagnoseStatus: (diagnoseStatus: components["schemas"]["ResumeDiagnoseStatusResult"]) => {
        set({ diagnoseStatus });
      },
      fetchDiagnoseResult: async (diagnoseId: string) => {
        set({ diagnoseResultLoading: true, currentDiagnoseId: diagnoseId });

        try {
          await delay(100);

          if (!diagnoseId || diagnoseId === "null") {
            throw new Error("diagnoseId is null");
          }

          const diagnoseResult = await getDiagnoseResult({
            diagnoseId,
          });
          if (!diagnoseResult) {
            throw new Error("diagnoseResult is empty");
          }

          const severityIssueMap = getSeverityIssueMap({
            resumeDiagnose: diagnoseResult.resumeDiagnose,
            severities: getNotEmptySeverities(diagnoseResult.resumeDiagnose?.totalIssues),
            sectionLayout: diagnoseResult.sectionLayout,
          });

          set(state => {
            return {
              diagnoseErrorOpen: false,
              diagnoseDiffOpen: state.isReDiagnose,
              // after first diganose, open report
              reportOpen: diagnoseResult.showGuidance || get().reportOpen,
              severityIssueMap,
              ...(diagnoseResult ?? {}),
              resumeIsMocking: diagnoseResult?.mocking,
              resumeTargetJobTitle: diagnoseResult?.targetJobTitle,
              resumeIsPrimary: diagnoseResult?.primary,
              currentResumeId: diagnoseResult?.resumeId,
              resumeName: diagnoseResult?.resumeName,
            };
          });

          // get().getCredit();
        } catch (e) {
          captureExceptionToSentry(`Fetch diagnose result error: ${e}`);
          set({
            diagnoseErrorOpen: true,
          });
        } finally {
          set({ isReDiagnose: false, diagnoseResultLoading: false });
        }
      },
      fetchPopupDiagnoseResult: async (diagnoseId: string) => {
        const diagnoseResult = await getDiagnoseResult({
          diagnoseId,
        });

        set({ popupResumeDiagnose: diagnoseResult?.resumeDiagnose });
      },
      startDiagnosing: async (resumeId: string, isReDiagnose?: boolean) => {
        set({ diagnoseErrorOpen: false });
        try {
          await postStartDiagnose({
            resumeId: resumeId || get().currentResumeId || "",
          });
          set({
            isReDiagnose: typeof isReDiagnose === "boolean" ? isReDiagnose : true,
            diagnoseStatus: {},
            resumeGlobalStatus: {
              status: RESUME_DIAGNOSE_STATUS.DIAGNOSING,
            },
          });
        } catch (e) {
          if ((e as AxiosError)?.response?.status === ANALYSIS_ERROR_CODE) {
            set({ diagnosingOpen: false });
            return ANALYSIS_ERROR_CODE;
          } else {
            set({ diagnoseErrorOpen: true });
          }
        }
      },
      cancelDiagnose: async () => {
        set({ diagnosingOpen: false });
        try {
          await postCancelDiagnose();
        } catch (e) {
          captureExceptionToSentry(`Cancel diagnose error: ${e}`);
        }
      },
      updateResumeAsync: async params => {
        set({ resumeChanging: true });

        try {
          await postResumeUpdate(params);
          await delay(100);
        } finally {
          set({ resumeChanging: false });
        }
      },
      addResumeItemAsync: async (params: components["schemas"]["ResumeAddItemRequest"]) => {
        set({ resumeChanging: true });

        try {
          await postResumeItemAdd(params);
        } finally {
          set({ resumeChanging: false });
        }
      },
      deleteResumeItemAsync: async (params: components["schemas"]["ResumeDeleteItemRequest"]) => {
        set({ resumeChanging: true });

        try {
          await postResumeItemDelete(params);
        } finally {
          set({ resumeChanging: false });
        }
      },
      setPersonalInfo: (options: components["schemas"]["PersonalInfo"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              personalInfo: {
                ...state.resumeDiagnose?.personalInfo,
                ...options,
              },
            },
          };
        });
      },
      setSummary: (summary: string) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              summary: {
                ...state.resumeDiagnose?.summary,
                summary,
              },
            },
          };
        });
      },
      setEducation: (education: components["schemas"]["Education"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              education,
            },
          };
        });
      },
      setProjects: (projects: components["schemas"]["Project"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              projects,
            },
          };
        });
      },
      setWorkExprience: (workExperience: components["schemas"]["WorkExperience"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              workExperience,
            },
          };
        });
      },
      setCertifications: (certifications: components["schemas"]["Certification"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              certifications,
            },
          };
        });
      },

      setLanguages: (languages: components["schemas"]["Language"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              languages,
            },
          };
        });
      },

      setPublications: (publications: components["schemas"]["Publication"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              publications,
            },
          };
        });
      },

      setExtracurricular: (extracurricular: components["schemas"]["Extracurricular"]) => {
        set(state => {
          return {
            resumeDiagnose: { ...state.resumeDiagnose, extracurricular },
          };
        });
      },

      setAchievements: (achievements: components["schemas"]["Achievement"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              achievements,
            },
          };
        });
      },

      setSkills: (skills: components["schemas"]["Skill"]["skills"]) => {
        set(state => {
          return {
            resumeDiagnose: {
              ...state.resumeDiagnose,
              skills: {
                ...state.resumeDiagnose?.skills,
                skills,
              },
            },
          };
        });
      },

      setInValidModalOpen: open => {
        set({ inValidModalOpen: open });
      },

      setDiagnoseErrorOpen: open => {
        set({ diagnoseErrorOpen: open });
      },

      setDiagnosingOpen: open => {
        set({ diagnosingOpen: open });
      },

      setDiagnoseDiffOpen: open => {
        set({ diagnoseDiffOpen: open });
      },

      setReportOpen: open => {
        set({ reportOpen: open });
      },

      setResumeTourOpen: open => {
        set({ resumeTourOpen: open });
      },

      setTourStep: step => {
        set({ tourStep: step });
      },

      setSeverityIssueMap: severityIssueMap => {
        set({ severityIssueMap });
      },

      setWriterFeedbackPopupOpen: show => {
        set({ writerFeedbackPopupOpen: show });
      },

      setExportFeedbackPopupOpen: show => {
        set({ exportFeedbackPopupOpen: show });
      },

      setShowResumeWriterFeedbackPopup: show => {
        set({ showResumeWriterFeedbackPopup: show });
      },

      setShowResumeExportFeedbackPopup: show => {
        set({ showResumeExportFeedbackPopup: show });
      },

      setShowResumeTailorFeedbackPopup: show => {
        set({ showResumeTailorFeedbackPopup: show });
      },

      setShowMobileUseResume: showMobileUseResume => {
        set(() => ({ showMobileUseResume }));
      },
      setSectionLayout: sectionLayout => {
        set({ sectionLayout: [...sectionLayout] });
      },

      setResumeIsMocking: isMocking => {
        set({ resumeIsMocking: isMocking });
      },

      setResumeTargetJobTitle: title => {
        set({ resumeTargetJobTitle: title });
      },

      setResumeIsPrimary: isPrimary => {
        set({ resumeIsPrimary: isPrimary });
      },
      setResumeName: (name: string | null) => {
        if (name) {
          set({ resumeName: name });
        }
      },

      setResumeDiagnose: (diagnose: components["schemas"]["ResumeDiagnose"] | null) => {
        if (diagnose) {
          set({ resumeDiagnose: diagnose });
        }
      },

      setResumeId: (id: string | null) => {
        if (id) {
          set({ currentResumeId: id });
        }
      },
      setResumeGlobalStatus: (
        status: components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]["result"]
      ) => {
        set({ resumeGlobalStatus: status });
      },

      setResumeInitialData: (
        initialData: components["schemas"]["BaseResponseResumeDiagnoseResult"]["result"],
        diagnoseId: string
      ) => {
        set({
          resumeIsMocking: initialData?.mocking,
          resumeTargetJobTitle: initialData?.targetJobTitle,
          resumeIsPrimary: initialData?.primary,
          resumeName: initialData?.resumeName,
          resumeDiagnose: initialData?.resumeDiagnose,
          currentResumeId: initialData?.resumeId,
          currentDiagnoseId: diagnoseId,
          sectionLayout: initialData?.sectionLayout,
        });
      },

      setResumeInfoEditPopupOpen: open => {
        set({ resumeInfoEditPopupOpen: open });
      },

      resetCurrentPageResume: () => {
        set({
          resumeIsMocking: null,
          resumeTargetJobTitle: null,
          resumeIsPrimary: null,
          resumeName: "",
          resumeDiagnose: undefined,
          currentResumeId: undefined,
          currentDiagnoseId: undefined,
          diagnoseStatus: {},
        });
      },

      setPopupResumeDiagnose: (diagnose: components["schemas"]["ResumeDiagnose"]) => {
        set({ popupResumeDiagnose: diagnose });
      },

      setShowCreateNewResumePopup: (show: boolean) => {
        set({ showCreateNewResumePopup: show });
      },

      setRefreshResumeCollection: (func: null | (() => void)) => {
        set({ refreshResumeCollection: func });
      },
      setCurrentPollingResumeId: (id: string | null) => {
        set({ currentPollingResumeId: id });
      },
    }),
    {
      name: "resume",
      enabled: process.env.NODE_ENV === "development",
    }
  )
);
