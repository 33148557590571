import { ConfigProvider } from "antd";
import { Inter } from "next/font/google";
import React from "react";

import { JOB_RIGHT_PRIMARY_COLOR } from "@/constants";

const inter = Inter({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const withTheme = (node: JSX.Element) => (
  <>
    <ConfigProvider
      theme={{
        token: {
          colorBgMask: "rgba(0, 0, 0, 0.8)",
          colorInfo: JOB_RIGHT_PRIMARY_COLOR,
          colorPrimary: JOB_RIGHT_PRIMARY_COLOR,
          colorText: "#000000",
          colorBgSpotlight: "#000000",
          fontFamily: inter.style.fontFamily,
        },
        components: {
          Modal: {
            borderRadiusLG: 24,
            boxShadow: "none",
            contentBg: "#fff",
          },
          Button: {
            defaultShadow: "none",
          },
          Slider: {
            handleLineWidth: 2,
            handleSize: 24,
            handleSizeHover: 24,
            handleActiveColor: "#000000",
            handleColor: "#000000",
            railSize: 8,
            railBg: "rgba(242, 244, 245, 0.60)",
            railHoverBg: "rgba(242, 244, 245, 0.60)",
            trackHoverBg: JOB_RIGHT_PRIMARY_COLOR,
            trackBg: JOB_RIGHT_PRIMARY_COLOR,
          },
          Select: {
            optionActiveBg: "rgba(0, 240, 160, 0.3)",
            optionSelectedBg: "rgba(0, 240, 160, 0.3)",
            optionFontSize: 13,
            optionLineHeight: "20px",
            optionPadding: "8px",
          },
          Form: {
            labelColor: "#000000",
            labelFontSize: 13,
            labelRequiredMarkColor: "#FF465A",
          },
          Input: {
            hoverBorderColor: "none",
            activeBorderColor: "none",
            activeShadow: "none",
          },
          Steps: {
            iconFontSize: 14,
          },
          Drawer: {
            colorBgTextActive: "#f2f4f5",
            colorBgTextHover: "#f2f4f5",
          },
          Checkbox: {
            controlInteractiveSize: 20,
            colorWhite: "#000",
            borderRadiusSM: 3,
            lineWidth: 0,
          },
        },
      }}
    >
      {node}
    </ConfigProvider>
  </>
);

export default withTheme;
