import { AB_TEST_STORAGE_KEYS, VISITOR_STORAGE_KEYS } from "@/enums";
import * as Sentry from "@sentry/nextjs";
import { useMount } from "ahooks";
import { useEffect, useState } from "react";

import { analytics, isBrowser, localStorageUtil, trackEvent } from "@/utils";
import { generateUniqId } from "@/utils/uniqId";

export const useInitialize = () => {
  const [visitorId, setVisitorId] = useState<string>(() => {
    const _visitId = generateUniqId().substring(0, 19);

    if (!isBrowser) return _visitId;

    return localStorageUtil.get(VISITOR_STORAGE_KEYS.VISITOR_ID) || _visitId;
  });

  useEffect(() => {
    // if no visitor id in storage, set up a new one
    if (!localStorageUtil.get(VISITOR_STORAGE_KEYS.VISITOR_ID)) {
      localStorageUtil.set(VISITOR_STORAGE_KEYS.VISITOR_ID, visitorId);
    }

    analytics.setUserProperty("visitid", visitorId);
    Sentry.setTag("jr_visitid", visitorId);
  }, [visitorId]);

  useMount(async () => {
    // if there is no device id, generate a new one
    let deviceId;
    if (!localStorageUtil.get(VISITOR_STORAGE_KEYS.DEVICE_ID)) {
      try {
        const clientjs = await import("clientjs");
        deviceId = new clientjs.ClientJS().getFingerprint();
      } catch (e) {
        deviceId = Date.now();
      }

      localStorageUtil.set(VISITOR_STORAGE_KEYS.DEVICE_ID, String(deviceId));
    }
  });

  useEffect(() => {
    if (!isBrowser) return;

    import("detectincognitojs").then(({ detectIncognito }) => {
      detectIncognito().then(result => {
        trackEvent("user_incognito", {
          is_private: Number(result.isPrivate),
          browser: result.browserName,
        });
      });
    });
  }, []);

  return {
    visitorId,
  };
};
