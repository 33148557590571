export function loadScript(url: string, position: "head" | "body" = "body") {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.defer = true;
    script.src = url;
    script.onload = (res) => {
      resolve(res);
    };
    script.onerror = (err) => {
      reject(err);
    };
    document[position].appendChild(script);
  });
}
