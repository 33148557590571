export const isBrowser = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

export const getNavigation = () => {
  return {
    pathname: isBrowser ? window.location.pathname : "",
    searchParams: isBrowser ? new URLSearchParams(window.location.search) : null,
  };
};

export const getWidth = () => {
  return { width: isBrowser ? window.innerWidth : undefined };
};

export const getAgent = () => {
  return navigator.userAgent.toString();
};

export const isAppinnerBrowser = () => {
  if (!isBrowser) return false;

  const userAgentInfo = navigator.userAgent; // 获取 User Agent 字符串
  // check if it is in app inner browser
  if (userAgentInfo.search(/micromessenger/i) >= 0) {
    return true; // weixin
  } else if (userAgentInfo.search(/(alipayclient|alipaydefined)/i) >= 0) {
    return true; // zhifubao
  } else if (userAgentInfo.search(/(iphone|ipad|ipod)/i) >= 0) {
    return false; // iOS
  } else if (userAgentInfo.search(/android/i) >= 0) {
    return false; // android
  } else if (userAgentInfo.search(/windows phone/i) >= 0) {
    return false;
  } else {
    return true;
  }
};

export const isLinkedInApp = () => {
  const userAgentInfo = navigator.userAgent; // 获取 User Agent 字符串
  if (userAgentInfo.search(/(LinkedInApp)/i) >= 0) {
    return true; // linkedin
  }

  return false;
};

export const isTiktokOrInstagramBrowser = () => {
  if (!isBrowser) return false;

  const userAgentInfo = navigator.userAgent; // 获取 User Agent 字符串
  if (userAgentInfo.search(/instagram/i) >= 0) {
    return true; // instagram
  } else if (userAgentInfo.search(/(LinkedInApp)/i) >= 0) {
    return true; // linkedin
  } else if (userAgentInfo.search(/(tiktok|bytelocale)/i) >= 0) {
    return true; // tikTok
  }
};
