import { COMMON_SIDE_POPUP_TYPE, REFERRAL_TYPE, SETTING_MENU_KEY } from "@/enums/jobs/referral";
import "@/enums/jobs/resume";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { fetchPreferenceTags } from "@/services/preference-filters";
import { getTestData } from "@/services/referral";
import {
  getCreditFeed,
  getPaymentSubscription,
  getReferralBalance,
  getUserSettings,
} from "@/services/referral";
import { getUnsubOffer, getUnsubOfferActivate } from "@/services/resume";
import { components } from "@/services/schema";

import { trackEvent } from "@/utils";

import { getBillingPortal } from "../services/referral";

interface ReferralStore {
  referralType: REFERRAL_TYPE | null;
  setReferralType: (type: REFERRAL_TYPE | null) => void;

  referralPopupOpen: boolean;
  setReferralPopupOpen: (open: boolean) => void;

  creditFetched: boolean;
  baseCredit: number | undefined;
  tailorCredit: number | undefined;
  emailCredit: number | undefined;

  subscribed: boolean;
  getCredit: () => void;

  isComplete: boolean;
  setIsComplete: (isComplete: boolean) => void;

  settingPopupOpen: boolean;
  setSettingPopupOpen: (open: boolean, selectedKey?: SETTING_MENU_KEY) => void;

  paymentPopupOpen: boolean;
  setPaymentPopupOpen: (open: boolean) => void;

  selectedKey: SETTING_MENU_KEY;
  setSelectedKey: (key: SETTING_MENU_KEY) => void;

  jobAlert?: number;
  showTailorPopup?: boolean;
  showResumeWriterFeedbackPopup?: boolean;
  showResumeExportFeedbackPopup?: boolean;
  showResumeTailorFeedbackPopup?: boolean;

  showPaymentSurvey: boolean;

  getSettings: () => void;
  updateSettings: (settings: components["schemas"]["UserSettingsResult"]) => void;

  openBillingPortal: (withTracker?: boolean) => void;

  subscription: components["schemas"]["SubscriptionDetailResult"];

  fetchingSubscription: boolean;
  setFetchingSubscription: (value: boolean) => void;
  getSubscription: () => Promise<components["schemas"]["SubscriptionDetailResult"]>;
  clearSubscription: () => void;

  feedbackPopupOpen: boolean;
  setFeedbackPopupOpen: (value: boolean) => void;

  updateLogOpen: boolean;
  setUpdateLogOpen: (value: boolean) => void;

  updateLogModalOpen: boolean;
  setUpdateLogModalOpen: (value: boolean) => void;

  useResumePopupOpen: boolean;
  setUseResumePopupOpen: (value: boolean) => void;

  turboOfficeHourPopupOpen: boolean;
  setTurboOfficeHourPopupOpen: (value: boolean) => void;

  toBCandidatePopupOpen: boolean;
  setToBCandidatePopupOpen: (value: boolean) => void;

  commonSidePopupType: COMMON_SIDE_POPUP_TYPE | null;
  setCommonSidePopupType: (value: COMMON_SIDE_POPUP_TYPE | null) => void;

  paymentSurverPopupOpen: boolean;
  setPaymentSurverPopupOpen: (value: boolean) => void;

  creditFeed: components["schemas"]["FreeCreditResult"];
  getCreditFeed: () => void;

  appPopupOpen: boolean;
  setAppPopupOpen: (value: boolean) => void;
  // AB Test Type
  testVersion: boolean;
  setTestVersion: (userId: string, registerTime: string) => void;
  fetchingTestVersion: boolean;
  setFetchingTestVersion: (value: boolean) => void;

  eduExpGroup: string;
  // Student Verify Email Send Status
  isSentStudenEmail: boolean;
  setIsSentStudenEmail: () => void;

  studentPopupOpen: boolean;
  setStudentPopupOpen: (value: boolean) => void;

  mobileInstallBannerOpen: boolean;
  setMobileInstallBannerOpen: (value: boolean) => void;

  unsubscribePopupOpen: boolean;
  setUnsubscribePopupOpen: (value: boolean) => void;

  unsubscribeFormPopupOpen: boolean;
  setUnsubscribeFormPopupOpen: (value: boolean) => void;

  unsubOfferData: components["schemas"]["UnsubscribeOfferResult"];
  setUnsubOfferData: () => void;

  unsubOfferActivate: boolean;
  setUnsubOfferActivate: (value: boolean) => void;

  unsubscribeSpecialsPopupOpen: boolean;
  setUnsubscribeSpecialsPopupOpen: (value: boolean) => void;

  messageCenterOpen: boolean;
  messageCenterDefaultMessageId: number | null;

  setMessageCenterOpen: (value: boolean) => void;
  setMessageCenterDefaultMessageId: (value: number | null) => void;
}

const initialReferralState = {
  referralType: null,
  referralPopupOpen: false,

  creditFetched: false,
  baseCredit: undefined,
  tailorCredit: undefined,
  emailCredit: undefined,
  subscribed: false,

  settingPopupOpen: false,
  isComplete: false,

  paymentPopupOpen: false,

  selectedKey: SETTING_MENU_KEY.LOGIN,

  jobAlert: 0,
  showTailorPopup: false,
  showResumeWriterFeedbackPopup: false,
  showResumeExportFeedbackPopup: false,
  showResumeTailorFeedbackPopup: false,

  showPaymentSurvey: false,

  subscription: {},
  fetchingSubscription: false,

  feedbackPopupOpen: false,

  updateLogOpen: false,

  updateLogModalOpen: false,

  useResumePopupOpen: false,
  paymentSurverPopupOpen: false,

  turboOfficeHourPopupOpen: false,
  toBCandidatePopupOpen: false,

  commonSidePopupType: null,

  creditFeed: {},

  appPopupOpen: false,

  testVersion: false,
  fetchingTestVersion: false,
  isSentStudenEmail: false,

  studentPopupOpen: false,
  eduExpGroup: "",

  // 移动端提示安装的banner显隐情况
  mobileInstallBannerOpen: false,

  unsubscribePopupOpen: false,
  unsubscribeFormPopupOpen: false,
  unsubscribeSpecialsPopupOpen: false,

  unsubOfferData: {},
  unsubOfferActivate: false,

  messageCenterOpen: false,

  messageCenterDefaultMessageId: null,
};

export const useReferralStore = create<ReferralStore>()(
  devtools(
    (set, get) => ({
      ...initialReferralState,

      setReferralType: referralType => set(() => ({ referralType })),

      setReferralPopupOpen: open => set(() => ({ referralPopupOpen: open })),

      getCredit: async () => {
        const balance = await getReferralBalance();

        set(() => ({
          creditFetched: true,
          baseCredit: balance?.credit?.base,
          tailorCredit: balance?.credit?.tailor,
          emailCredit: balance?.credit?.email,
          subscribed: balance?.subscribed,
        }));
      },

      getSettings: async () => {
        const settings = await getUserSettings();
        set(() => ({ ...settings }));
      },

      updateSettings: settings => {
        set(() => ({ ...settings }));
      },

      setIsComplete: isComplete => set(() => ({ isComplete })),

      setSettingPopupOpen: (open, selectedKey = SETTING_MENU_KEY.LOGIN) =>
        set(() => ({ settingPopupOpen: open, selectedKey })),

      setPaymentPopupOpen: open => set(() => ({ paymentPopupOpen: open })),

      setSelectedKey: key => set(() => ({ selectedKey: key })),

      openBillingPortal: async (withTracker = true) => {
        if (withTracker) {
          trackEvent("setting_subscription_click", {
            action: "manage subscription",
          });
        }
        const url = await getBillingPortal();
        window.open(url, "_blank");
      },

      setFetchingSubscription: value => set(() => ({ fetchingSubscription: value })),

      getSubscription: async () => {
        if (get().fetchingSubscription) return initialReferralState.subscription;

        get().setFetchingSubscription(true);

        try {
          const subscription = await getPaymentSubscription();
          set(() => ({ subscription }));
          return subscription;
        } finally {
          get().setFetchingSubscription(false);
        }
      },

      clearSubscription: () => set(() => ({ subscription: null! })),

      setFeedbackPopupOpen: value => set(() => ({ feedbackPopupOpen: value })),

      setUpdateLogOpen: value => set(() => ({ updateLogOpen: value })),

      setUpdateLogModalOpen: value => set(() => ({ updateLogModalOpen: value })),

      setUseResumePopupOpen: value => set(() => ({ useResumePopupOpen: value })),

      setTurboOfficeHourPopupOpen: value => set(() => ({ turboOfficeHourPopupOpen: value })),

      setToBCandidatePopupOpen: value => set(() => ({ toBCandidatePopupOpen: value })),

      setCommonSidePopupType: value => set(() => ({ commonSidePopupType: value })),

      setPaymentSurverPopupOpen: value => set(() => ({ paymentSurverPopupOpen: value })),

      getCreditFeed: async () => {
        const creditFeed = await getCreditFeed();
        set(() => ({ creditFeed }));
      },

      setAppPopupOpen: value => set(() => ({ appPopupOpen: value })),

      setTestVersion: async (userId: string, registerTime: string) => {
        get().setFetchingTestVersion(true);

        try {
          const [seniorityData, subscription] = await Promise.all([
            fetchPreferenceTags(),
            getPaymentSubscription(),
          ]);
          const seniority = seniorityData?.seniority?.join(",");
          const testData = await getTestData(userId, {
            platform: "pc",
            seniority: String(seniority),
            registerTime: Number(registerTime),
            turboPriceName: subscription ? subscription.priceName! : "",
            turboStatus: subscription ? subscription.status + "" : "",
          });

          return set(() => ({
            testVersion: ["on", "alternate"].includes(testData?.config?.turbo_intern as string),
            eduExpGroup: testData?.config?.turbo_intern,
          }));
        } finally {
          get().setFetchingTestVersion(false);
        }
      },

      setFetchingTestVersion: value => set(() => ({ fetchingTestVersion: value })),

      setIsSentStudenEmail: () => set(() => ({ isSentStudenEmail: true })),

      setStudentPopupOpen: value => {
        setTimeout(() => {
          set(() => ({ studentPopupOpen: value }));
        }, 5000);
      },

      setMobileInstallBannerOpen: value => set(() => ({ mobileInstallBannerOpen: value })),

      setUnsubscribePopupOpen: value => set(() => ({ unsubscribePopupOpen: value })),
      setUnsubscribeFormPopupOpen: value => set(() => ({ unsubscribeFormPopupOpen: value })),
      setUnsubscribeSpecialsPopupOpen: value =>
        set(() => ({ unsubscribeSpecialsPopupOpen: value })),

      setUnsubOfferData: async () => {
        const unsubOfferData = await getUnsubOffer();
        return set(() => ({
          unsubOfferData,
        }));
      },

      setUnsubOfferActivate: async () => {
        const unsubOfferActivate = await getUnsubOfferActivate();
        return set(() => ({
          unsubOfferActivate,
        }));
      },

      setMessageCenterOpen: value => set(() => ({ messageCenterOpen: value })),
      setMessageCenterDefaultMessageId: value =>
        set(() => ({ messageCenterDefaultMessageId: value })),
    }),
    {
      name: "referral",
      enabled: process.env.NODE_ENV === "development",
    }
  )
);
