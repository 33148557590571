import { RESUME_FEEDBACK_TYPE } from "@/enums/jobs/resume";
import { API_HOST } from "@/global";
import { TDiagnoseReport } from "@/types/jobs/resume";
import axios from "axios";

import {
  IResumeTailorKeyRequirement,
  ResumeAlignState,
} from "@/components/Jobs/TailorResumeDrawer/type";

import { parseResponse } from "../utils/services";
import { components, operations } from "./schema";

export const postStartDiagnose = (params: { resumeId: string }) => {
  return axios
    .post<components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]>(
      `${API_HOST}/swan/resume-rewriter/diagnose/start`,
      null,
      {
        params,
      }
    )
    .then(res => parseResponse<components["schemas"]["ResumeDiagnoseStatusResult"]>(res));
};

export const postCancelDiagnose = () => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/diagnose/cancel`)
    .then(res => parseResponse<boolean>(res));
};

export const getDiagnoseStatus = (params: { resumeId: string }) => {
  return axios
    .get<
      components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]
    >(`${API_HOST}/swan/resume-rewriter/diagnose/status`, { params })
    .then(res => parseResponse<components["schemas"]["ResumeDiagnoseStatusResult"]>(res));
};

export const getDiagnoseResult = ({ diagnoseId }: { diagnoseId: string }) => {
  if (!diagnoseId || diagnoseId === "null") {
    throw new Error("diagnoseId is null");
  }

  return axios
    .get<components["schemas"]["BaseResponseResumeDiagnoseResult"]>(
      `${API_HOST}/swan/resume-rewriter/diagnose/result`,
      {
        params: {
          diagnoseId,
        },
      }
    )
    .then(res => parseResponse<components["schemas"]["ResumeDiagnoseResult"]>(res));
};

export const getDiagnoseReport = ({ diagnoseId }: { diagnoseId: string }) => {
  return axios
    .get<components["schemas"]["BaseResponseJsonNode"]>(
      `${API_HOST}/swan/resume-rewriter/diagnose/report`,
      {
        params: {
          diagnoseId,
        },
      }
    )
    .then(res =>
      parseResponse<{
        resumeDiagnose: TDiagnoseReport;
      }>(res)
    );
};

export const postResumeUpdate = (params: components["schemas"]["ResumeUpdateItemRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/update`, params)
    .then(res => parseResponse<boolean | undefined>(res));
};

export const postResumeItemAdd = (params: components["schemas"]["ResumeAddItemRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/add`, params)
    .then(res => parseResponse<components["schemas"]["ResumeAddItemResult"]>(res));
};

export const postResumeItemDelete = (params: components["schemas"]["ResumeDeleteItemRequest"]) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/delete`, params)
    .then(res => parseResponse<boolean | undefined>(res));
};

export const postResumeItemDeleteSection = (
  params: components["schemas"]["ResumeSectionAddOrDeleteRequest"]
) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/delete/section`, params)
    .then(res => parseResponse<boolean | undefined>(res));
};

export const postResumeItemAddSection = (
  params: components["schemas"]["ResumeSectionAddOrDeleteRequest"]
) => {
  return axios
    .post<
      components["schemas"]["BaseResponseBoolean"]
    >(`${API_HOST}/swan/resume-rewriter/add/section`, params)
    .then(res => parseResponse<boolean | undefined>(res));
};

export const getResumeDiagnoseIssue = (params: { _id: string }) => {
  return axios
    .get<components["schemas"]["BaseResponseResumeDiagnoseIssueResult"]>(
      `${API_HOST}/swan/resume-rewriter/diagnose/issue`,
      {
        params,
      }
    )
    .then(res => parseResponse<components["schemas"]["ResumeDiagnoseIssueResult"]>(res));
};

export const getResumeFile = (formData: FormData) => {
  return axios
    .post(`${API_HOST}/swan/resume/pdf-to-doc`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob",
    })
    .then(res => res?.data);
};

export const postResumeGuidence = () => {
  return axios.post(`${API_HOST}/swan/resume-rewriter/guidance/mark`).then(res => res?.data);
};

export const postResumeFeedbackState = (type: RESUME_FEEDBACK_TYPE) => {
  return axios
    .post(`${API_HOST}/swan/resume-rewriter/feedback/mark?type=${type}`)
    .then(res => res?.data);
};

export const submitResumeFeedack = (
  params: components["schemas"]["ResumeWriterFeedbackRequest"]
) => {
  return axios
    .post(`${API_HOST}/swan/feedback/resume-writer/submit`, params)
    .then(res => res?.data);
};
export const postTailorResumeOverview = (
  params: components["schemas"]["ResumeTailorOverviewRequest"]
) => {
  return axios.post<components["schemas"]["BaseResponseMapStringObject"]>(
    `${API_HOST}/swan/resume-tailor/overview`,
    params
  );
};

export const getTailorResumeDiff = (params: components["schemas"]["ResumeTailorRequest"]) => {
  return axios
    .post(`${API_HOST}/swan/resume-tailor/diff`, params)
    .then(res => parseResponse<ResumeAlignState>(res));
};

export const getTailorResumePrep = (params: components["schemas"]["ResumeTailorPrepRequest"]) => {
  return axios
    .post(`${API_HOST}/swan/resume-tailor/prep`, params)
    .then(res => parseResponse<{ keyRequirements: IResumeTailorKeyRequirement[] }>(res));
};

export const saveTailorResult = (
  tailorId: string,
  params: components["schemas"]["SaveResumeTailorRequest"]
) => {
  return axios.post<components["schemas"]["BaseResponseBoolean"]>(
    `${API_HOST}/swan/resume-tailor/save?tailorId=${tailorId}`,
    params
  );
};

// export const getTailorResumeDoc = (
//   tailorId: string,
//   params: components["schemas"]["ResumeTailorExportDocRequest"]
// ) => {
//   return axios
//     .post(`${API_HOST}/swan/resume-tailor/export-doc?tailorId=${tailorId}`, params, {
//       responseType: "blob",
//     })
//     .then(res => res?.data);
// };

export const postTailorPopupFlag = () => {
  return axios
    .post<components["schemas"]["BaseResponseBoolean"]>(`${API_HOST}/swan/resume-tailor/popup-flag`)
    .then(res => res?.data);
};

export const getResumeCollection = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseListResumeCollectionResult"]
    >(`${API_HOST}/swan/resume/collection/get`)
    .then(res => {
      return res?.data?.result;
    });
};

export const postResumeCollectionInfoUpdate = (
  params: components["schemas"]["ResumeCollectionUpdateRequest"]
) => {
  return axios.post(`${API_HOST}/swan/resume/collection/update`, params).then(res => {
    return parseResponse<components["schemas"]["BaseResponseBoolean"]>(res);
  });
};

export const postSetPrimaryResume = (params: { resumeId: string }) => {
  return axios
    .post(`${API_HOST}/swan/resume/collection/primary/set`, null, {
      params: params,
    })
    .then(res => {
      return res.data as components["schemas"]["BaseResponse"];
    });
};

export const deleteResumeCollection = (params: { resumeId: string }) => {
  return axios
    .post(`${API_HOST}/swan/resume/collection/delete`, null, {
      params: params,
    })
    .then(res => {
      return parseResponse<components["schemas"]["BaseResponseInteger"]>(res);
    });
};

export const getPollingResumeUploadStatus = (params: { resumeId: string }) => {
  return axios
    .get<components["schemas"]["BaseResponseInteger"]>(`${API_HOST}/swan/resume/upload/status`, {
      params: params,
    })
    .then(res => parseResponse<number>(res));
};

export const getGlobalResumeOverallStatus = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]
    >(`${API_HOST}/swan/resume-rewriter/diagnose/global-status`)
    .then(res =>
      parseResponse<components["schemas"]["BaseResponseResumeDiagnoseStatusResult"]["result"]>(res)
    );
};

export const cancelResumeUpload = (params: { resumeId: string }) => {
  return axios.post(`${API_HOST}/swan/resume/upload/cancel`, null, { params: params });
};

export const getResumeName = (params: { resumeId: string }) => {
  return axios
    .get<components["schemas"]["BaseResponseString"]>(`${API_HOST}/swan/resume/name`, {
      params,
    })
    .then(res => parseResponse<components["schemas"]["BaseResponseString"]>(res));
};

export const postTailorFeedback = (
  params: components["schemas"]["ResumeTailorFeedbackRequest"]
) => {
  return axios.post("/swan/feedback/tailor", params).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const posUnsubscribeFeedback = (
  params: components["schemas"]["UnsubscribeFeedbackRequest"]
) => {
  return axios.post("/swan/feedback/unsubscribe", params).then(response => {
    return parseResponse<boolean>(response);
  });
};

export const getUnsubOffer = () => {
  return axios
    .get("/swan/payment/unsub-offer", {})
    .then(res => parseResponse<components["schemas"]["UnsubscribeOfferResult"]>(res));
};

export const getUnsubOfferActivate = () => {
  return axios
    .get("/swan/payment/unsub-offer/activate", {})
    .then(res => parseResponse<boolean>(res));
};

export const getTailorResumeHistory = (params: { jobId: string }) => {
  return axios
    .get<components["schemas"]["BaseResponseResumeTailorHistoryResult"]>(
      `${API_HOST}/swan/resume-tailor/history`,
      {
        params,
      }
    )
    .then(res => parseResponse<components["schemas"]["ResumeTailorHistoryResult"]>(res));
};

export const applyTailorResume = (params: { jobId: string; impId: string }) => {
  return axios
    .get(`${API_HOST}/swan/resume-tailor/apply`, { params })
    .then(res =>
      parseResponse<components["schemas"]["BaseResponseMapStringObject"]["result"]>(res)
    );
};
