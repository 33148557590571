import { API_HOST } from "@/global";
import axios from "axios";

import { parseResponse } from "@/utils/services";

import { components } from "./schema";

export const getReferralHost = () => {
  return axios
    .get<components["schemas"]["BaseResponseReferralHostResult"]>(`${API_HOST}/swan/referral/host`)
    .then(res => parseResponse<components["schemas"]["ReferralHostResult"]>(res));
};

export const getReferralBalance = () => {
  return axios
    .get<components["schemas"]["BaseResponseCreditBO"]>(`${API_HOST}/swan/credit/balance-v2`)
    .then(res =>
      parseResponse<{ credit: components["schemas"]["CreditBO"]; subscribed: boolean }>(res)
    );
};

export const getCreditFeed = () => {
  return axios
    .get<components["schemas"]["BaseResponseFreeCreditResult"]>(`${API_HOST}/swan/credit/free`)
    .then(res => parseResponse<components["schemas"]["FreeCreditResult"]>(res));
};

export const getPaymentSubscription = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseSubscriptionDetailResult"]
    >(`${API_HOST}/swan/payment/subscription`)
    .then(res => parseResponse<components["schemas"]["SubscriptionDetailResult"]>(res));
};

export const getBillingPortal = () => {
  return axios
    .get(`${API_HOST}/swan/payment/billing-portal`)
    .then(res => parseResponse<string>(res));
};

export const getPaymentPrice = (utm_campaign?: string) => {
  return axios
    .get(`${API_HOST}/swan/payment/price` + (utm_campaign ? `?utm_campaign=${utm_campaign}` : ""))
    .then(res => parseResponse<components["schemas"]["PriceResult"]>(res));
};

export const getTestData = (
  user: string,
  vars: {
    platform: string;
    seniority: string;
    registerTime: number;
    turboPriceName: string;
    turboStatus: string;
  }
) => {
  return axios
    .get(`${API_HOST}/swan/ab/user?user=${user}&vars=${JSON.stringify(vars)}`)
    .then(res => parseResponse<components["schemas"]["ABResult"]>(res));
};

export const saveUserSettings = (params: components["schemas"]["SaveUserSettingsRequest"]) => {
  return axios.post<components["schemas"]["BaseResponse"]>(
    `${API_HOST}/swan/user-settings/save`,
    params
  );
};

export const getUserSettings = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseUserSettingsResult"]
    >(`${API_HOST}/swan/user-settings/get`)
    .then(res => parseResponse<components["schemas"]["UserSettingsResult"]>(res));
};

export const getPaymentStatus = () => {
  return axios
    .get<
      components["schemas"]["BaseResponseSubscriptionDetailResult"]
    >(`${API_HOST}/swan/payment/sub-status`)
    .then(res => parseResponse<components["schemas"]["SubscriptionDetailResult"]>(res));
};

export const getCandidateInfo = () => {
  return axios
    .get(`${API_HOST}/swan/popup/candidate`)
    .then(res => parseResponse<string | undefined>(res));
};

export const getTurboPopup = () => {
  return axios.get(`${API_HOST}/swan/popup/turbo`).then(res =>
    parseResponse<{
      cta: string;
      ctaUrl: string;
      description: string;
      time: number;
      title: string;
    }>(res)
  );
};

export const fetchFreeTrialOffer = async (payload: components["schemas"]["TrialOfferRequest"]) => {
  return axios.post(`${API_HOST}/swan/payment/trial-offer`, payload).then(res => {
    return parseResponse<components["schemas"]["BaseResponseInteger"]["result"]>(res);
  });
};
