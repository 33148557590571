var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pigeon_production@v0.0.731"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/nextjs";

const FILTER_MSG_LIST = [
  "Non-Error promise rejection captured with value: ",
  "resume is required for viewing job detail",
  "ReportingObserver [deprecation]",
  "ResizeObserver loop completed with undelivered notifications",
  "Non-Error promise rejection captured with value",
  "Hydration Error",
  "Hydration",
  "Replay buffering",
];

// import { hitSample } from "@/utils/sentry";

console.info("** [JR] info process.env.RELEASE_VERSION **", process.env.RELEASE_VERSION);
console.info("** [JR] info process.env.RELEASE_TIME **", process.env.RELEASE_TIME);

const buildEnvToConfig: Record<
  string,
  {
    dsn: string;
    tracesSampleRate: number;
    replaysOnErrorSampleRate: number;
    replaysSessionSampleRate: number;
  }
> = {
  development: {
    dsn: "https://619dcbe346c04431a7846c47dbaf5c9f@o4505347608150016.ingest.sentry.io/4505348395433984",
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
  },
  staging: {
    dsn: "https://619dcbe346c04431a7846c47dbaf5c9f@o4505347608150016.ingest.sentry.io/4505348395433984",
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
  },
  production: {
    dsn: "https://5f46138160b2461b9e0fb4bb1cc803bc@o4505347608150016.ingest.sentry.io/4505347610378240",
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    // replaysOnErrorSampleRate: 0,
    // replaysSessionSampleRate: 0,
  },
};

if (process.env.BUILD_ENV && buildEnvToConfig[process.env.BUILD_ENV]) {
  Sentry.init({
    // sampleRate: 0.1,

    release: process.env.RELEASE_VERSION,

    environment: process.env.BUILD_ENV,

    dsn: buildEnvToConfig[process.env.BUILD_ENV].dsn,

    ignoreErrors: ["AxiosError: Request aborted", "AxiosError: Network Error", "Hydration Error"],

    // https://docs.sentry.io/platforms/javascript/configuration/filtering/
    beforeSend: event => {
      // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651

      if (Array.isArray(event?.exception?.values) && event?.exception?.values?.length) {
        const eventString = event.exception.values.map(v => v?.value || "").join(";");

        if (FILTER_MSG_LIST.some(msg => eventString.includes(msg))) {
          return null;
        }
      }

      const SPIDERS = ["Googlebot", "Baiduspider-render", "Google-InspectionTool", "bingbot"];
      const eventUserAgent = event.request?.headers?.["User-Agent"];
      if (
        eventUserAgent &&
        SPIDERS.some(spider => eventUserAgent.includes(spider))
        // && !hitSample(0.01)
      ) {
        return null;
      }

      return event;
    },

    // https://docs.sentry.io/platforms/javascript/guides/express/configuration/filtering/#filtering-transaction-events
    beforeSendTransaction(event, hint) {
      if (event.transaction === "/api/events" || event.transaction === "POST /api/events") {
        // Don't send the event to Sentry
        return null;
      }
      return event;
    },

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: buildEnvToConfig[process.env.BUILD_ENV].tracesSampleRate,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: buildEnvToConfig[process.env.BUILD_ENV].replaysOnErrorSampleRate,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: buildEnvToConfig[process.env.BUILD_ENV].replaysSessionSampleRate,

    // By default, error events don't contain header or cookie data. You can change this behavior by setting sendDefaultPii: true in your root Sentry.init({}) config.
    sendDefaultPii: true,

    attachStacktrace: true,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        // maskAllText: true,
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        // networkDetailAllowUrls: ["https://api2.amplitude.com/2/httpapi"],
        networkDetailAllowUrls: [window.location.origin],
        networkCaptureBodies: true,
        beforeErrorSampling: event => {
          if (event?.request?.url?.includes("api/events")) {
            return false;
          }
          if (
            (event.level !== "error" && event.level !== "fatal") ||
            FILTER_MSG_LIST.some(msg => event?.message?.includes(msg))
          ) {
            return false;
          }

          return true;
        },
      }),
      httpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[400, 599]],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        // failedRequestTargets: [
        //   "http:localhost:3000/",
        //   "https://swan-api.jobright-internal.com/swan/:path*",
        //   "https://swan-api.jobright.ai/swan/:path*",
        //   process.env.BACKEND_API_DESTINATION || "",
        // ],
      }),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
  });
}
