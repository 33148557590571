import { GOOGLE_AUTH_CLIENT_ID_DEV, GOOGLE_AUTH_CLIENT_ID_PROD } from "@/global";

export function getClientID() {
  // console.log("** [JR] log process.env.BUILD_ENV **", process.env.BUILD_ENV);
  if (process.env.BUILD_ENV === "production") {
    return GOOGLE_AUTH_CLIENT_ID_PROD;
  } else if (["development", "testing", "staging"].includes(process.env.BUILD_ENV ?? "")) {
    return GOOGLE_AUTH_CLIENT_ID_DEV;
  }

  return GOOGLE_AUTH_CLIENT_ID_DEV;
}
