// Desc: constants for configuration here.

export const API_HOST = process.env.API_HOST || "";

export const GOOGLE_AUTH_CLIENT_ID_DEV =
  "139970931980-5otniplreop0rj04l0bdovprisatk9g3.apps.googleusercontent.com";
export const GOOGLE_AUTH_CLIENT_ID_PROD =
  "587377326476-kldmuvq21c4oopeinlgin6g4dcr38oiu.apps.googleusercontent.com";

export const BACKEND_API_DOMAIN_MAP: Record<string, string> = {
  production: "https://swan-api.jobright.ai",
  staging: "https://swan-api.jobright-internal.com",
  development: "https://swan-api.jobright-internal.com",
};
