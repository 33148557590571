export enum REFERRAL_TYPE {
  base = "base",
  tailor = "tailor",
  email = "email",
}

export enum SETTING_MENU_KEY {
  LOGIN = "LOGIN",
  SUBSCRIPTION = "SUBSCRIPTION",
  JOB_ALERT = "JOB_ALERT",
}

export enum JOB_ALERTS_SORTER {
  "NONE" = 0,
  "1_ALERT_A_DAY" = 1,
  "2_ALERTS_A_DAY" = 2,
  "5_ALERTS_A_DAY" = 5,
  "UNLIMITED" = 100,
}

export enum COMMON_SIDE_POPUP_TYPE {
  TO_B_CANDIDATE = "TO_B_CANDIDATE",
  PAYMENT_SURVER = "PAYMENT_SURVER",
  TURBO_OFFICE_HOUR = "TURBO_OFFICE_HOUR",
}
