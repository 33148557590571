import { LANDING_ROUTE_KEY, MENU_KEY, PROFILE_CURRENT_STAGE } from "@/enums";

import { RECOMMEND_PATHNAME } from "./jobs/joblist";
import {
  ABOUT_PATHNAME,
  AI_COVER_LETTER_GENERATOR,
  AI_JOB_ASSISTANT,
  AI_JOB_TRACKER,
  AI_RESUME_HELPER,
  BLOG_PATHNAME,
  COPILOT_ORION_LANDING_PATHNAME,
  ENERGIZE_YOUR_JOB_SEARCH,
  ENTERPRISE_INVITE_PATHNAME,
  ENTRY_LEVEL_JOBS,
  H1B_LANDING_PATHNAME,
  HOW_TO_GET_A_INTERNSHIP,
  HOW_TO_WRITE_A_RESUME,
  INTERN_LIST_PATHNAME,
  JOBRIGHT_FOR_GOOD,
  JOBS_PROFILE_PATHNAME,
  JOBS_RESUME_PATHNAME,
  JOB_MATCHING_PATHNAME,
  JOB_REFERRAL_PATHNAME,
  LAND_TOP_INTERNSHIP,
  LINKEDIN_101_GUIDE,
  MASTERING_INTERVIEWS,
  NEW_GRAD_PATHNAME,
  ONBOARDING_V3_RESUME_PATHNAME,
  PRIVACY_POLICY_PATHNAME,
  RESUME_LANDING_PATHNAME,
  TERMS_OF_SERVICE_PATHNAME,
  TOOLS_ASSEMBLE,
  UNLIMATE_GUIDE_TO_H1B,
} from "./pathname";

export * from "./filter";

export const PROFILE_STATE_LINK_ENUM: {
  [key: number]: string;
} = {
  0: "/",
  [PROFILE_CURRENT_STAGE.NO_FILTER]: "/onboarding-v3/mode-selection", // TO_ONBOARDING_FILTER
  [PROFILE_CURRENT_STAGE.NO_RESUME]: "/onboarding-v3/mode-selection", // TO_UPLOAD_RESUME

  [PROFILE_CURRENT_STAGE.RESUME_PARSING]: "/matching", // "RESUME_ANALYSIS_WAITING(waiting for ups parsing)"
  [PROFILE_CURRENT_STAGE.FILTE_RESUME_READY]: "/jobs/recommend", // TO_GET_JOB_LIST

  [PROFILE_CURRENT_STAGE.FAILED_RESUME]: ONBOARDING_V3_RESUME_PATHNAME, // TO_UPLOAD_RESUME (Parse resume failed)
  [PROFILE_CURRENT_STAGE.FAILED_WITHOUT_FILTER]: "/jobs/recommend", // SPECIAL_CASE: OLD USER UPLOADED RESUME BUT NOT FILLED FILTER

  [PROFILE_CURRENT_STAGE.V3_TO_SEEKER_TYPE]: "/onboarding-v3/mode-selection", // TO_SEEKER_TYPE
  [PROFILE_CURRENT_STAGE.V3_RUSH_TO_BASIC_PREF]: "/onboarding-v3/diagnostics", // TO_ADVANCED_PREF
  [PROFILE_CURRENT_STAGE.V3_NO_RUSH_TO_BASIC_PREF]: "/onboarding-v3/diagnostics", // TO_ADVANCED_PREF
  [PROFILE_CURRENT_STAGE.V3_TO_CAREER_GOAL]: "/onboarding-v3/career-goals", // TO_CAREER_GOAL
  [PROFILE_CURRENT_STAGE.V3_TO_ADVANCED_PREF]: "/onboarding-v3/advanced-preferences", // TO_ADVANCED_PREF
  [PROFILE_CURRENT_STAGE.V3_RUSH_TO_RESUME]: "/onboarding-v3/resume-upload", // TO_ADVANCED_PREF
  [PROFILE_CURRENT_STAGE.V3_NOT_RUSH_TO_RESUME]: "/onboarding-v3/resume-upload", // TO_ADVANCED_PREF
};

export const JR_USER_ID_EDGE_CASE = {
  LOGINED_NOT_JR_USER: "logined but no jr_userid",
  NOT_LOGINED: "not logined",
};

export const RADIUS_RANGE_OPTIONS = [
  {
    value: 0,
    label: "0mi",
  },
  {
    value: 5,
    label: "5mi",
  },
  {
    value: 25,
    label: "25mi",
  },
  {
    value: 50,
    label: "50mi",
  },
  {
    value: 100,
    label: "100mi",
  },
];

export const H1B_FILTER_TOOLTIP = `This filter helps you find jobs that either explicitly state H1B support ("H1B Sponsored") or come from companies with a recent history of sponsoring H1B visas for similar roles ("H1B Sponsor Likely").`;

export const NO_H1B_TAG = "No H1B";
export const H1B_SPONSOR_LIKELY = "H1B Sponsor Likely";
export const H1B_SPONSORED = "H1B Sponsored";

export const US_CITIZEN_ONLY = "U.S. Citizen Only";
export const SECURITY_CLEARANCE_REQUIRED = "Security Clearance Required";

export const H1B_TAG_TOOLTIP = {
  [NO_H1B_TAG]: "This job explicitly states that H1B sponsorship is not supported.",
  [H1B_SPONSOR_LIKELY]:
    "While this job description doesn’t mention H1B sponsorship, the company has a history of sponsoring similar roles in the past three years.",
  [H1B_SPONSORED]: "This job explicitly states that H1B sponsorship is supported.",
  [US_CITIZEN_ONLY]: "This job is only open to U.S. citizens.",
  [SECURITY_CLEARANCE_REQUIRED]: "This job requires an active security clearance.",
};

export const FAST_TYPELIST_DELAY = {
  typingDelay: 5,
  // avgTypingDelay: 5,
  stdTypingDelay: 1,
};

export const SLOW_TYPELIST_DELAY = {
  typingDelay: 10,
};

export const LINKEDIN_URL_PREFIX = "https://www.linkedin.com/in/";

export const RESUME_ACCEPT_EXTENSION = ".pdf, .doc, .docx";

export const JOB_RIGHT_PRIMARY_COLOR = "#00F0A0";

export const MESSAGE_DURATION = 2;

export const MOBILE_LANDING_ANIMATION_THRESHOLD = 0.1;

export const RATING_ITEM_COVER_CONFIG_GROUP = [
  {
    value: `9.1/10`,
    desc: "Quality Improvement Rating",
  },
  {
    value: "2 Hrs",
    desc: "# of Editing Hours saved per job",
  },
  {
    value: "10 million",
    desc: "# of jobs our AI is trained on",
  },
];

export const RATING_ITEM_CONFIG_GROUP = [
  {
    value: `9.1/10`,
    desc: "Quality Improvement Rating",
  },
  {
    value: "5 Hrs",
    desc: "# of Editing Hours saved per job",
  },
  {
    value: "10 million",
    desc: "# of jobs our AI is trained on",
  },
];

export const RATING_ASSISTANT_ITEM_CONFIG_GROUP = [
  {
    value: `9.1/10`,
    desc: "Quality Improvement Rating",
  },
  {
    value: "20 mins",
    desc: "# Of Managing Time saved per job",
  },
  {
    value: "10 million",
    desc: "# of jobs our AI is trained on",
  },
];

export const ADVANTAGES_CTA_LIST = [
  "Endorsed by HR professionals",
  "We Protect Your Data",
  "ATS Friendly",
  "Resumes Match Industry Standards",
  "Better Response Rates",
  "Optimized Skills for Your Resume",
];

export const LANDING_YOUTUBE_VIDEO_LINK = "https://youtu.be/f2oQsk2er0Y";

export const LANDING_DISCORD_LINK = "https://discord.gg/tJeyxxsef9";

export const MOBILE_MENU_ITEM_KEYS = [MENU_KEY.LIST, MENU_KEY.PROFILE, MENU_KEY.RESUME];
export const MOBILE_MENU_ITEM_ENUM: {
  [key: string]: {
    key: string;
    selectedIconSrc: string;
    iconSrc: string;
    route: string;
    text: string;
  };
} = {
  [MENU_KEY.LIST]: {
    key: MENU_KEY.LIST,
    selectedIconSrc: "/newimages/nav/jobs_sel.svg",
    iconSrc: "/newimages/nav/jobs.svg",
    route: RECOMMEND_PATHNAME,
    text: "Jobs",
  },
  [MENU_KEY.PROFILE]: {
    key: MENU_KEY.PROFILE,
    selectedIconSrc: "/newimages/nav/profile_sel.svg",
    iconSrc: "/newimages/nav/profile.svg",
    route: JOBS_PROFILE_PATHNAME,
    text: "Profile",
  },
  [MENU_KEY.RESUME]: {
    key: MENU_KEY.RESUME,
    selectedIconSrc: "/newimages/nav/resume_sel.svg",
    iconSrc: "/newimages/nav/resume.svg",
    route: JOBS_RESUME_PATHNAME,
    text: "Resume",
  },
};

export const MOBILE_LANDING_MENU_ITEM_KEYS = [
  LANDING_ROUTE_KEY.RESUME_AI,

  LANDING_ROUTE_KEY.DIVIDER,

  LANDING_ROUTE_KEY.JOB_MATCHING,
  LANDING_ROUTE_KEY.INSIDER_CONNECTIONS,
  LANDING_ROUTE_KEY.AI_COPILOT_ORION,
  LANDING_ROUTE_KEY.H1B_JOBS,
  LANDING_ROUTE_KEY.INTERN_LIST,
  LANDING_ROUTE_KEY.NEW_GRAD,

  LANDING_ROUTE_KEY.DIVIDER,

  LANDING_ROUTE_KEY.FOR_EMPLOYER,

  LANDING_ROUTE_KEY.DIVIDER,

  LANDING_ROUTE_KEY.ABOUT_US,
  LANDING_ROUTE_KEY.BLOG,
];

export const LANDING_MENU_ITEM_ENUM: Record<
  string,
  {
    key: string;
    route: string;
    text: string;
  }
> = {
  [LANDING_ROUTE_KEY.RESUME_AI]: {
    key: LANDING_ROUTE_KEY.RESUME_AI,
    route: RESUME_LANDING_PATHNAME,
    text: "Resume AI",
  },

  [LANDING_ROUTE_KEY.JOB_MATCHING]: {
    key: LANDING_ROUTE_KEY.JOB_MATCHING,
    route: JOB_MATCHING_PATHNAME,
    text: "AI Job Match",
  },
  [LANDING_ROUTE_KEY.INSIDER_CONNECTIONS]: {
    key: LANDING_ROUTE_KEY.INSIDER_CONNECTIONS,
    route: JOB_REFERRAL_PATHNAME,
    text: "Insider Connections",
  },
  [LANDING_ROUTE_KEY.AI_COPILOT_ORION]: {
    key: LANDING_ROUTE_KEY.AI_COPILOT_ORION,
    route: COPILOT_ORION_LANDING_PATHNAME,
    text: "AI Copilot Orion",
  },
  [LANDING_ROUTE_KEY.H1B_JOBS]: {
    key: LANDING_ROUTE_KEY.H1B_JOBS,
    route: H1B_LANDING_PATHNAME,
    text: "H1B Jobs",
  },
  [LANDING_ROUTE_KEY.ABOUT_US]: {
    key: LANDING_ROUTE_KEY.ABOUT_US,
    route: ABOUT_PATHNAME,
    text: "About Us",
  },
  [LANDING_ROUTE_KEY.BLOG]: {
    key: LANDING_ROUTE_KEY.BLOG,
    route: BLOG_PATHNAME,
    text: "Blog",
  },
  [LANDING_ROUTE_KEY.INTERN_LIST]: {
    key: LANDING_ROUTE_KEY.INTERN_LIST,
    route: INTERN_LIST_PATHNAME,
    text: "Ultimate Intern Jobs",
  },

  [LANDING_ROUTE_KEY.LINKEDIN_101_GUIDE]: {
    key: LANDING_ROUTE_KEY.LINKEDIN_101_GUIDE,
    route: LINKEDIN_101_GUIDE,
    text: "Linkedin 101 Guide",
  },

  [LANDING_ROUTE_KEY.UNLIMATE_GUIDE_TO_H1B]: {
    key: LANDING_ROUTE_KEY.UNLIMATE_GUIDE_TO_H1B,
    route: UNLIMATE_GUIDE_TO_H1B,
    text: "The Ultimate Guide to H1B Application",
  },

  [LANDING_ROUTE_KEY.LAND_TOP_INTERNSHIP]: {
    key: LANDING_ROUTE_KEY.LAND_TOP_INTERNSHIP,
    route: LAND_TOP_INTERNSHIP,
    text: "How to Land A Top Internship",
  },

  [LANDING_ROUTE_KEY.NEW_GRAD]: {
    key: LANDING_ROUTE_KEY.NEW_GRAD,
    route: ENTRY_LEVEL_JOBS,
    text: "Top Entry Level Jobs",
  },
  [LANDING_ROUTE_KEY.JOBRIGHT_FOR_GOOD]: {
    key: LANDING_ROUTE_KEY.JOBRIGHT_FOR_GOOD,
    route: JOBRIGHT_FOR_GOOD,
    text: "Jobright for Good",
  },

  [LANDING_ROUTE_KEY.PRIVACY_POLICY]: {
    key: LANDING_ROUTE_KEY.PRIVACY_POLICY,
    route: PRIVACY_POLICY_PATHNAME,
    text: "Privacy Policy",
  },

  [LANDING_ROUTE_KEY.TERMS_OF_SERVICE]: {
    key: LANDING_ROUTE_KEY.TERMS_OF_SERVICE,
    route: TERMS_OF_SERVICE_PATHNAME,
    text: "Terms of Service",
  },

  [LANDING_ROUTE_KEY.MASTERING_INTERVIEWS]: {
    key: LANDING_ROUTE_KEY.TERMS_OF_SERVICE,
    route: MASTERING_INTERVIEWS,
    text: "Mastering Interviews",
  },
  [LANDING_ROUTE_KEY.ENERGIZE_YOUR_JOB_SEARCH]: {
    key: LANDING_ROUTE_KEY.ENERGIZE_YOUR_JOB_SEARCH,
    route: ENERGIZE_YOUR_JOB_SEARCH,
    text: "Energize Your Job Search",
  },
  [LANDING_ROUTE_KEY.HOW_TO_WRITE_A_RESUME]: {
    key: LANDING_ROUTE_KEY.HOW_TO_WRITE_A_RESUME,
    route: HOW_TO_WRITE_A_RESUME,
    text: "How To Write A Resume",
  },
  [LANDING_ROUTE_KEY.HOW_TO_GET_A_INTERNSHIP]: {
    key: LANDING_ROUTE_KEY.HOW_TO_GET_A_INTERNSHIP,
    route: HOW_TO_GET_A_INTERNSHIP,
    text: "AI Job Assistant",
  },

  // Tools
  [LANDING_ROUTE_KEY.AI_JOB_ASSISTANT]: {
    key: LANDING_ROUTE_KEY.AI_JOB_ASSISTANT,
    route: AI_JOB_ASSISTANT,
    text: "AI Job Assistant",
  },
  [LANDING_ROUTE_KEY.AI_COVER_LETTER_GENERATOR]: {
    key: LANDING_ROUTE_KEY.AI_COVER_LETTER_GENERATOR,
    route: AI_COVER_LETTER_GENERATOR,
    text: "AI Cover Letter Generator",
  },
  [LANDING_ROUTE_KEY.AI_RESUME_HELPER]: {
    key: LANDING_ROUTE_KEY.AI_RESUME_HELPER,
    route: AI_RESUME_HELPER,
    text: "AI Resume Helper",
  },
  [LANDING_ROUTE_KEY.AI_JOB_TRACKER]: {
    key: LANDING_ROUTE_KEY.AI_JOB_TRACKER,
    route: AI_JOB_TRACKER,
    text: "AI Job Tracker",
  },
  [LANDING_ROUTE_KEY.TOOLS_ASSEMBLE]: {
    key: LANDING_ROUTE_KEY.TOOLS_ASSEMBLE,
    route: TOOLS_ASSEMBLE,
    text: "Explore All",
  },

  [LANDING_ROUTE_KEY.FOR_EMPLOYER]: {
    key: LANDING_ROUTE_KEY.FOR_EMPLOYER,
    route: ENTERPRISE_INVITE_PATHNAME,
    text: "For Employer",
  },
};

export const JOBRIGHT_EXTENSION_ID = "jobright-helper-plugin";
