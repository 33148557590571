import { API_HOST } from "@/global";
import axios, { AxiosResponse } from "axios";

import { parseResponse } from "@/utils/services";

import { components, operations, paths } from "./schema";
import { ResetPasswordParams } from "./types";

axios.defaults.withCredentials = true;

export const signIn = (params: components["schemas"]["LoginRequest"]) => {
  return axios.post<components["schemas"]["BaseResponse"]>(
    `${API_HOST}/swan/auth/login/pwd`,
    params
  );
};

export const signOut = () => {
  return axios.get(`${API_HOST}/swan/auth/logout`);
};

export const signInWithGoogleSSO = (params: components["schemas"]["GoogleSsoLoginRequest"]) => {
  return axios.post(`${API_HOST}/swan/auth/login/sso `, params);
};

export const fetchUserProfileV2 = (): Promise<
  AxiosResponse<
    components["schemas"]["BaseResponseUserProfileResult"] | components["schemas"]["BaseResponse"]
  >
> => {
  return axios.get(`${API_HOST}/swan/auth/newinfo`);
};

export const sendUserFeedbackV2 = (params: components["schemas"]["FeedbackRequestV2"]) => {
  return axios.post(`${API_HOST}/swan/feedback/submit-v2`, params).then(res => {
    return res.data as components["schemas"]["BaseResponse"];
  });
};

export const sendForgotPasswordEmail = (email: string) => {
  return axios.post(
    `${API_HOST}/swan/auth/forget/password`,
    {
      email,
    },
    {
      withCredentials: true,
    }
  );
};

export const submitNewPassword = (params: ResetPasswordParams) => {
  return axios.post(`${API_HOST}/swan/auth/reset/password`, params, {
    withCredentials: true,
  });
};

export const signUpWithGoogleSSOV3 = (
  params: components["schemas"]["GoogleSsoRegisterV3Request"]
) => {
  return axios.post<components["schemas"]["BaseResponse"]>(
    `${API_HOST}/swan/auth/register/sso-v3`,
    params
  );
};

export const signUpWithPasswordV3 = (params: components["schemas"]["RegisterV3Request"]) => {
  return axios.post<components["schemas"]["BaseResponse"]>(
    `${API_HOST}/swan/auth/register/pwd-v3`,
    params
  );
};

export const getUserABTest = (params: { user: string }) => {
  return axios.get(`${API_HOST}/swan/ab/user`, { params }).then(res => {
    return parseResponse<components["schemas"]["ABResult"]>(res);
  });
};

export const deleteUserAccount = () => {
  return axios.post(`${API_HOST}/swan/auth/cancel-account`);
};

export const sendVerifyEmail = (email: string) => {
  return axios
    .post(`${API_HOST}/swan/auth/edu/email`, { email })
    .then(res => parseResponse<boolean>(res));
};

export const verifyStudent = (code: string) => {
  return axios
    .post(`${API_HOST}/swan/auth/edu/verify`, { code })
    .then(res => parseResponse<boolean>(res));
};
