import { USER_STORAGE_KEY, VISITOR_STORAGE_KEYS } from "@/enums";
import * as amplitude from "@amplitude/analytics-browser";
import * as Sentry from "@sentry/nextjs";
import { isEmpty } from "lodash-es";
import MobileDetect from "mobile-detect";

import { JR_USER_ID_EDGE_CASE } from "@/constants";

import { AmplitudeAnalytics } from "./amplitude";
import events from "./events";
import { localStorageUtil } from "./storage";

export const analytics = new AmplitudeAnalytics();

/**
 * to track amplitude event by sentry
 * @param eventInput string
 * @param eventProperties
 */
const trackEvent = (
  eventInput: string,
  eventProperties?: Record<string, any>,
  transport?: "xhr" | "sendBeacon"
) => {
  const JRuserId =
    localStorageUtil.get(USER_STORAGE_KEY.USER_ID) || JR_USER_ID_EDGE_CASE["NOT_LOGINED"];
  const AMPuserId = String(amplitude.getUserId());
  const AMPdeviceId = String(amplitude.getDeviceId());
  const visitId = String(localStorageUtil.get(VISITOR_STORAGE_KEYS.VISITOR_ID));

  const sentryPayload = {
    user: {
      jr_visitid: visitId,
      jr_userid: JRuserId,
      amp_userid: AMPuserId,
      amp_deviceid: AMPdeviceId,
    },
  };

  // handle eventProperties
  let _eventProperties: Record<string, any> | undefined;

  // serialization and deserialization on eventProperties, filter some case like { foo: undefined }
  try {
    const propertiesString = JSON.stringify(eventProperties || {});
    _eventProperties = JSON.parse(propertiesString);
  } catch (error) {
    Sentry.captureEvent({
      level: "error",
      message: "Amplitude track event properties parse error",
      ...sentryPayload,
      tags: {
        ...sentryPayload.user,
        track_properties: String(JSON.stringify(eventProperties)),
      },
    });
  }

  let finalEventProperties: Record<string, any> | undefined;

  try {
    // NOTE: filter {} eventProperties
    // const finalEventProperties = isEmpty(_eventProperties) ? undefined : _eventProperties;
    finalEventProperties = isEmpty(_eventProperties) ? {} : _eventProperties;

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.phone();

    finalEventProperties = isMobile
      ? {
          platform: "mobile",
          ...finalEventProperties,
        }
      : {
          platform: "pc",
          ...finalEventProperties,
        };

    // NOTE: upload to amplitude
    analytics.trackEvent(eventInput, finalEventProperties);

    events.uploadEvent(
      {
        channel: finalEventProperties?.scene ? finalEventProperties?.scene : "default",
        eventType: eventInput,
        eventDetail: finalEventProperties,
      },
      {
        transport,
      }
    );
  } catch (error) {
    Sentry.captureEvent({
      level: "error",
      message: `[Amplitude track event error: ${error}]`,
      ...sentryPayload,
      tags: {
        ...sentryPayload.user,
        track_properties: String(JSON.stringify(eventProperties)),
      },
    });
  }
};

export { trackEvent };
