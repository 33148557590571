import { VISITOR_STORAGE_KEYS } from "@/enums";
import { useInitialize } from "@/hooks/useInitialize";
import { createContext, useContext } from "react";

export const GlobalContext = createContext<{
  [VISITOR_STORAGE_KEYS.VISITOR_ID]: string;
}>({
  [VISITOR_STORAGE_KEYS.VISITOR_ID]: "",
});

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { visitorId } = useInitialize();

  return (
    <GlobalContext.Provider
      value={{
        [VISITOR_STORAGE_KEYS.VISITOR_ID]: visitorId,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
