export enum RECOMMAND_SORTER {
  "RECOMMENDED" = 0, // RELEVANCE(0, "RELEVANCE"),
  "MOST_RECENT" = 1, // FRESHNESS(1, "FRESHNESS"),
  "TOP_MATCHED" = 2, // MATCH_SCORE(2, "MATCH_SCORE"),
}

export enum JOB_LIST_FEEDBACK {
  company = 1,
  title = 2,
  skill = 3,
  irrelevant = 4,
  applied = 5,
  other = 6,
  salary = 7,
  location = 8,
}

export enum JOB_FEEDBACK {
  scam = 1,
  offensive = 2,
  incorrect = 3,
  not_availiable = 4,
}

export enum EXTERNAL_JOB_IMPORT_STATUS {
  NOT_FOUND = -1,
  INIT = 0,
  ENQUEUED = 1,
  IMPORTED = 2,
  FAILED = 3,
}

export enum APPLIED_JOB_STATUS {
  "APPLIED" = 0,
  "INTERVIEWING" = 1,
  "OFFER_RECEIVED" = 2,
  "REJECTED" = 3,
  "ARCHIVED" = 4,
}
