export const INTERN_LEVEL = 1;
export const ENTRY_LEVEL = 2;
export const MID_LEVEL = 3;
export const SENIOR_LEVEL = 4;
export const DIRECTOR = 5;
export const EXECUTIVE = 6;

export const DEFAULT_RADIUS = 25;
export const EXPERIENCE_LEVELS_OPTIONS = [
  {
    value: INTERN_LEVEL,
    label: "Intern/New Grad",
    tooltip:
      "No work experience required, aimed at recent graduates, suitable for finding full-time or internship positions.",
  },
  {
    value: ENTRY_LEVEL,
    label: "Entry Level",
    tooltip: "1 to 2 years of experience, ideal for job seekers just starting their career.",
  },
  {
    value: MID_LEVEL,
    label: "Mid Level",
    tooltip:
      "Has worked for some time and gained experience but hasn’t reached the senior level yet.",
  },
  {
    value: SENIOR_LEVEL,
    label: "Senior Level",
    tooltip:
      "Has over 5 years of work experience and can independently manage projects. This level covers a broad range across different industries, such as a senior IC role for SDE, a sales manager for sales, or VP/AVP in the financial sector.",
  },
  {
    value: DIRECTOR,
    label: "Lead/Staff",
    tooltip:
      "A further promotion after senior. For software engineers in IC roles, typical positions include staff or principal engineer. For product, marketing, sales, and other roles, this level often involves being a leader across teams or a transition from IC to management.",
  },
  {
    value: EXECUTIVE,
    label: "Director/Executive",
    tooltip: "Senior company management, responsible for strategic decision-making.",
  },
];

export const DATE_POSTED_OPTIONS = [
  {
    value: 1,
    label: "Past 24 hours", // 1 day
  },
  {
    value: 3,
    label: "Past 3 days", // 3 days
  },
  {
    value: 7,
    label: "Past week", // 7 days
  },
  {
    value: 30,
    label: "Past month", // 30 days
  },
];

export const ONSITE_MODE = 1;
export const REMOTE_MODE = 2;
export const HYBRID_MODE = 3;
export const WORK_MODE_OPTIONS = [
  {
    value: ONSITE_MODE,
    label: "Onsite",
  },
  {
    value: REMOTE_MODE,
    label: "Remote",
  },
  {
    value: HYBRID_MODE,
    label: "Hybrid",
  },
];

export const FULL_TIME = 1;
export const CONTRACT = 2;
export const PART_TIME = 3;
export const INTERNSHIP = 4;
export const JOB_TYPE_OPTIONS = [
  {
    value: FULL_TIME,
    label: "Full-time",
  },
  {
    value: CONTRACT,
    label: "Contract",
  },
  {
    value: PART_TIME,
    label: "Part-time",
  },
  {
    value: INTERNSHIP,
    label: "Internship",
  },
];
export const INITIAL_ANNUAL_SAlARY_MINIMUM = 40000;

export const COMPANY_STAGE_2_DISPLAY: Record<string, string> = {
  "Early Stage": "Early Stage",
  "Growth Stage": "Growth Stage",
  "Late Stage": "Late Stage",
  Public: "Public Company",
};

export const COMPANY_STAGE_OPTION = Object.entries(COMPANY_STAGE_2_DISPLAY).map(([key, value]) => ({
  value: key,
  label: value,
}));

export const ROLE_TYPE_OPTION = [
  {
    value: "IC",
    label: "IC",
  },
  {
    value: "Manager",
    label: "Manager",
  },
];

export const JOB_TITLE_JOIN_SEPARATOR = ", ";
export const JOB_TAXONOMY_DEFAULT_ID = "00-00-00";
