import { IsSsrMobileContext } from "@/hooks/useIsMobile";
import { GlobalProvider } from "@/providers/GlobalProvider";
import { ProfileContextProvider } from "@/providers/ProfileProvider";
import "@/styles/globals.less";
import withTheme from "@/theme";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import Router from "next/router";
import Script from "next/script";

// Async API cannot be server-side rendered
import "@/services/interceptor";

import { getClientID } from "@/utils";
import "@/utils/env";
import "@/utils/polyfill";
import "@/utils/trace";

import "../lib/polyfills";

// https://github.com/asyncapi/asyncapi-react/issues/177
type NewNextComponentType = NextComponentType<NextPageContext, any, any> & {
  getLayout: (page: React.ReactElement) => React.ReactElement;
};

const inter = Inter({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

function MyApp({ Component, pageProps }: AppProps<{ isMobile: boolean }>) {
  // Use the layout defined at the page level, if available
  const getLayout = (Component as NewNextComponentType).getLayout || ((page: any) => page);

  // useReportWebVitals(metric => {
  //   console.log(metric);
  // });

  // https://stackoverflow.com/questions/68263317/how-to-avoid-duplicated-meta-tags
  // https://stackoverflow.com/questions/68204973/avoid-duplicate-meta-description-and-keywords-in-next-js
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Meta Tags */}
        <meta name="type" property="og:type" content="website" />
        <meta name="url" property="og:url" content="https://jobright.ai/" />
        <meta name="title" property="og:title" content="Jobright: Your AI Job Search Copilot" />
        <meta
          property="og:description"
          key="og:description"
          content="Discover your dream job with Jobright, your AI job search copilot. Find relevant opportunities tailored to your goals. Gain filtered matches, deep insights, and stay ahead with real-time company trends. Let Jobright find your perfect fit."
        />
        <meta
          name="image"
          property="og:image"
          content="https://jobright.ai/newimages/seo_logo.png"
        />
        <meta name="og:site_name" property="og:site_name" content="Jobright AI" />
        <meta name="og:locale" property="og:locale" content="en" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" property="twitter:card" content="summary_large_image" />
        <meta name="twitter:domain" property="twitter:domain" content="jobright.ai" />
        <meta name="twitter:url" property="twitter:url" content="https://jobright.ai/" />
        <meta name="twitter:title" content="Jobright: Your AI Job Search Copilot" />
        <meta
          name="twitter:description"
          content="No more solo job hunt, do it with AI. Jobright helps you save 80% time and increase your chances of interviews by providing optimized job matches, tailoring your resume to each role, and offering in-depth support."
        />
        <meta name="twitter:image" content="https://jobright.ai/newimages/seo_logo.png" />

        {/* Facebook Meta */}
        <meta name="facebook-domain-verification" content="v3i3sgqc27apk3dj8zimn7j469ddk4" />
        {/* Facebook Ads related */}
        <noscript
          id="facebook-ads-noscript"
          dangerouslySetInnerHTML={{
            __html: `<img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=1375532296391406&ev=PageView&noscript=1"
            />`,
          }}
        ></noscript>
        <style
          dangerouslySetInnerHTML={{
            __html: `html {
              font-family: ${inter.style.fontFamily};
            }`,
          }}
        ></style>
      </Head>
      <main className={inter.className}>
        <GoogleOAuthProvider clientId={getClientID()}>
          <GlobalProvider>
            <ProfileContextProvider>
              <IsSsrMobileContext.Provider value={pageProps.isMobile}>
                {withTheme(getLayout(<Component {...pageProps} />))}
              </IsSsrMobileContext.Provider>
            </ProfileContextProvider>
          </GlobalProvider>
        </GoogleOAuthProvider>
      </main>
      {/* Facebook Ads */}
      <Script id="facebook-ads" strategy="lazyOnload">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1375532296391406');
          fbq('track', 'PageView');
          `}
      </Script>
      {/* Tiktok Ads */}
      <Script id="tiktok-ads" strategy="lazyOnload">{`
        setTimeout(() => {
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

            ttq.load('CM0IJ53C77U0797CAP10');
            ttq.page();
          }(window, document, 'ttq');
        }, 1000);
      `}</Script>

      {/* <!-- Google Ads --> */}
      <Script id="google-ads" strategy="lazyOnload">{`
        setTimeout(() => {
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TV56F46F');
        }, 1000);
      `}</Script>

      {/* <!-- Bing Ads --> */}
      <Script
        id="bing-ads"
        strategy="lazyOnload"
      >{`(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"343104944", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`}</Script>

      {/* <!-- Google tag (gtag.js) --> */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-11463835106"
        strategy="lazyOnload"
      ></Script>
      <Script id="gtm-datalayer" strategy="lazyOnload">{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-11463835106');
        gtag('config', 'G-ETKKWETCJD');
      `}</Script>

      {/* <Script async src="https://www.googletagmanager.com/gtag/js?id=G-ETKKWETCJD"></Script>
      <Script id="gtm-datalayer2">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-ETKKWETCJD');`}
      </Script> */}

      {/* <!-- End Google Tag Manager --> */}
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-TV56F46F"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        `,
        }}
      ></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}

      {/* <!-- Linkedin Ads --> */}
      <Script id="linkin-ads" strategy="lazyOnload">{`
        _linkedin_partner_id = "6243732";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);

        (function(l) {
        if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
        window.lintrk.q=[]}
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);
      `}</Script>
      {/* <!-- End Linkedin Ads --> */}
      {/* <!-- Linkedin Ads (noscript) --> */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=6243732&fmt=gif"
        />
          `,
        }}
      ></noscript>
      {/* <!-- End Linkedin Ads (noscript) --> */}

      {/* <!-- ahalab Code --> */}
      <Script
        async
        src="https://scripts.ahalab.io/track.js"
        data-aha-client-id="org_2qehOUDjMnhIgo8pY6w5nVkxpah"
      ></Script>

      {/* <!-- Plausible Analytics --> */}
      <Script
        defer
        data-domain="jobright.ai"
        src="https://plausible.io/js/script.js"
        strategy="lazyOnload"
        id="plausible-script"
      />

      {/* debug on mobile */}
      {/* <script src="//cdn.jsdelivr.net/npm/eruda"></script>
      <script>eruda.init();</script> */}
    </>
  );
}

// Temporary fix to avoid flash of unstyled content
const routeChange = () => {
  // Temporary fix to avoid flash of unstyled content
  // during route transitions. Keep an eye on this
  // issue and remove this code when resolved:
  // https://github.com/vercel/next.js/issues/17464

  const tempFix = () => {
    const allStyleElems = document.querySelectorAll('style[media="x"]');
    allStyleElems.forEach(elem => {
      elem.removeAttribute("media");
    });
  };
  tempFix();
};

Router.events.on("routeChangeComplete", routeChange);
Router.events.on("routeChangeStart", routeChange);

export default MyApp;
